import { Stack } from "@mui/material";

import { Token } from "src/slices/tokenSlice";

import CancelOrderButton from "src/components/TradeModal/CancelOrderButton";
import ListForSaleButton from "src/components/TradeModal/ListForSaleButton";
import ReservoirPrice from "src/components/TradeModal/ReservoirPrice";

const TradeModalMyListingContent = ({ token }: { token: Token }) => {
    const tokenId = token.token?.tokenId;
    const listingId = token?.market?.floorAsk?.id;

    return (
        <Stack pt={2} pb={2} gap={2}>
            {token.market?.floorAsk?.price && (
                <ReservoirPrice
                    price={token.market.floorAsk.price}
                    leftText="Listing price:"
                />
            )}

            {listingId && <ListForSaleButton token={token} />}
            {listingId && tokenId && (
                <CancelOrderButton
                    tokenId={Number(tokenId)}
                    modalTitle="Delete listing"
                    completeText="Your listing has been succesfully deleted"
                    orderId={listingId}
                />
            )}
        </Stack>
    );
};

export default TradeModalMyListingContent;
