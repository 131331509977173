import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReadContractErrorType } from "viem";

import { ContractType } from "src/hooks/useContract";
import { getNextWordIntroductionData as getNextWordIntroductionDataFromBackend } from "src/services/api";

import { RootState } from "src/store";

export const ContractState = {
    OPEN: 0,
    FREQUENCY_UPDATE: 1,
    NEW_WORDS_ADDITION: 2,
    SCALING_UP: 3,
};

interface IContractSettingsState {
    contractState: number;
    buyPrice: number;
    frequencyUpdateCounter?: number;
    wordsOpenToBeBought: string[];
    nextWordIntroductionData?: {
        date: string;
        wordNumbers: number;
    };
    statuses: Record<
        | "getRandomTokenPrice"
        | "getCurrentContractState"
        | "getContractFrequencyUpdateCounter"
        | "getWordsOpenToBeBought"
        | "getNextWordIntroductionData",
        "idle" | "loading" | "succeeded" | "failed"
    >;
    error: string | null;
}

const initialState: IContractSettingsState = {
    contractState: ContractState.OPEN,
    buyPrice: 0,
    frequencyUpdateCounter: undefined,
    nextWordIntroductionData: undefined,
    wordsOpenToBeBought: [],
    statuses: {
        getRandomTokenPrice: "idle",
        getCurrentContractState: "idle",
        getContractFrequencyUpdateCounter: "idle",
        getWordsOpenToBeBought: "idle",
        getNextWordIntroductionData: "idle",
    },
    error: null,
};

// Thunk for fetching the buy price
export const getRandomTokenPrice = createAsyncThunk(
    "contractSettings/getRandomTokenPrice",
    async (params: { contract: ContractType }, thunkAPI) => {
        const { contract } = params;
        try {
            const buyPrice = await contract.read.getRandomTokenPrice();
            return Number(buyPrice[0]);
        } catch (_error: unknown) {
            const error = _error as ReadContractErrorType;
            console.warn("Error while fetching buy price: ", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

// Thunk for fetching the contract state
export const getCurrentContractState = createAsyncThunk(
    "contractSettings/getCurrentContractState",
    async (params: { contract: ContractType }, thunkAPI) => {
        const { contract } = params;
        try {
            const contractState = await contract.read.currentContractState();
            return contractState;
        } catch (_error: unknown) {
            const error = _error as ReadContractErrorType;
            console.warn("Error while fetching currentContractState: ", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

// Thunk for fetching the contract state
export const getWordsOpenToBeBought = createAsyncThunk(
    "contractSettings/getWordsOpenToBeBought",
    async (params: { contract: ContractType }, thunkAPI) => {
        const { contract } = params;
        try {
            const wordsOpenToBeBought =
                await contract.read.getWordsOpenToBeBought();
            return wordsOpenToBeBought;
        } catch (_error: unknown) {
            const error = _error as ReadContractErrorType;
            console.warn("Error while fetching wordsOpenToBeBought: ", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

// Thunk for fetching the contract frequency update counter
export const getContractFrequencyUpdateCounter = createAsyncThunk(
    "contractSettings/getContractFrequencyUpdateCounter",
    async (params: { contract: ContractType }, thunkAPI) => {
        const { contract } = params;
        try {
            const frequencyUpdateCounter =
                await contract.read.frequencyUpdateCounter();
            return frequencyUpdateCounter
                ? Number(frequencyUpdateCounter.toString())
                : undefined;
        } catch (_error: unknown) {
            const error = _error as ReadContractErrorType;
            console.warn(
                "Error while fetching frequencyUpdateCounter: ",
                error,
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

// Thunk for fetching next word introduction date
export const getNextWordIntroductionData = createAsyncThunk(
    "contractSettings/getNextWordIntroductionData",
    async (_params, thunkAPI) => {
        try {
            const nextWordIntroductionData =
                await getNextWordIntroductionDataFromBackend();
            return nextWordIntroductionData;
        } catch (_error: unknown) {
            const error = _error as ReadContractErrorType;
            console.warn(
                "Error while fetching nextWordIntroductionData: ",
                error,
            );
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

export const contractSettingsSlice = createSlice({
    name: "contractSettings",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRandomTokenPrice.fulfilled, (state, { payload }) => {
                state.statuses.getRandomTokenPrice = "succeeded";
                state.buyPrice = payload;
                state.error = null;
            })
            .addCase(getRandomTokenPrice.pending, (state) => {
                state.statuses.getRandomTokenPrice = "loading";
                state.error = null;
            })
            .addCase(getRandomTokenPrice.rejected, (state, action) => {
                state.statuses.getRandomTokenPrice = "failed";
                state.error = action.payload as string;
            })
            .addCase(
                getCurrentContractState.fulfilled,
                (state, { payload }) => {
                    state.statuses.getCurrentContractState = "succeeded";
                    state.contractState = payload;
                    state.error = null;
                },
            )
            .addCase(getCurrentContractState.pending, (state) => {
                state.statuses.getCurrentContractState = "loading";
                state.error = null;
            })
            .addCase(getCurrentContractState.rejected, (state, action) => {
                state.statuses.getCurrentContractState = "failed";
                state.error = action.payload as string;
            })
            .addCase(
                getContractFrequencyUpdateCounter.fulfilled,
                (state, { payload }) => {
                    state.statuses.getContractFrequencyUpdateCounter =
                        "succeeded";
                    state.frequencyUpdateCounter = payload;
                    state.error = null;
                },
            )
            .addCase(getContractFrequencyUpdateCounter.pending, (state) => {
                state.statuses.getContractFrequencyUpdateCounter = "loading";
                state.error = null;
            })
            .addCase(
                getContractFrequencyUpdateCounter.rejected,
                (state, action) => {
                    state.statuses.getContractFrequencyUpdateCounter = "failed";
                    state.error = action.payload as string;
                },
            )
            .addCase(getWordsOpenToBeBought.fulfilled, (state, { payload }) => {
                state.statuses.getWordsOpenToBeBought = "succeeded";
                state.wordsOpenToBeBought = [...payload];
                state.error = null;
            })
            .addCase(getWordsOpenToBeBought.pending, (state) => {
                state.statuses.getWordsOpenToBeBought = "loading";
                state.error = null;
            })
            .addCase(getWordsOpenToBeBought.rejected, (state, action) => {
                state.statuses.getWordsOpenToBeBought = "failed";
                state.error = action.payload as string;
            })
            .addCase(
                getNextWordIntroductionData.fulfilled,
                (state, { payload }) => {
                    state.statuses.getNextWordIntroductionData = "succeeded";
                    state.nextWordIntroductionData = payload;
                    state.error = null;
                },
            )
            .addCase(getNextWordIntroductionData.pending, (state) => {
                state.statuses.getNextWordIntroductionData = "loading";
                state.error = null;
            })
            .addCase(getNextWordIntroductionData.rejected, (state, action) => {
                state.statuses.getNextWordIntroductionData = "failed";
                state.error = action.payload as string;
            });
    },
});

export const selectCurrentContractState = (state: RootState) =>
    state.contractSettings.contractState;
export const selectBuyPrice = (state: RootState) =>
    state.contractSettings.buyPrice;
export const selectContractSettingsStatuses = (state: RootState) =>
    state.contractSettings.statuses;

export const selectContractFrequencyUpdateCounter = (state: RootState) =>
    state.contractSettings.frequencyUpdateCounter;
export const selectWordsOpenToBeBought = (state: RootState) =>
    state.contractSettings.wordsOpenToBeBought;
export const selectIsContractStateOpen = (state: RootState) =>
    ["succeeded", "loading"].includes(
        state.contractSettings.statuses.getCurrentContractState,
    ) && state.contractSettings.contractState === ContractState.OPEN;
export const selectNextWordIntroductionData = (state: RootState) =>
    state.contractSettings.nextWordIntroductionData;

export default contractSettingsSlice.reducer;
