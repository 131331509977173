import { Chain, base, baseSepolia, hardhat, mainnet } from "viem/chains";

export const privatenet = {
    id: 8888,
    name: "Private",
    network: "private",
    nativeCurrency: {
        decimals: 18,
        name: "Ethereum",
        symbol: "ETH",
    },
    rpcUrls: {
        public: {
            http: ["https://anvil.soon.murmurprotocol.com"],
        },
        default: {
            http: ["https://anvil.soon.murmurprotocol.com"],
        },
    },
    blockExplorers: {
        default: {
            url: "https://anvil-blockscout.soon.murmurprotocol.com",
            name: "Private blockscout explorer",
        },
    },
    contracts: {},
};

export function getChainByStringID(id: string): Chain {
    switch (id) {
        case "1337":
            return {
                ...hardhat,
                blockExplorers: {
                    default: {
                        url: "http://localhost:4000",
                        name: "Local block explorer",
                    },
                },
            };
        case "8888":
            return privatenet;
        case "84532":
            return baseSepolia;
        case "8453":
            return base;
        default:
            return mainnet;
    }
}

export function getEnvDependentChain(): Chain {
    return getChainByStringID(import.meta.env.VITE_CHAIN_ID);
}

export const getExtraEnvChains = (): Chain[] => {
    const extraChains: Chain[] = [];

    const chain = getEnvDependentChain();
    if (chain !== base) {
        extraChains.push(chain);
    }

    return extraChains;
};

export function getDefaultChains(): Chain[] {
    const defaultChains = [getEnvDependentChain()];

    return defaultChains;
}
