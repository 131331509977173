import { Box, Typography } from "@mui/material";
import { definitions } from "@reservoir0x/reservoir-sdk";

export default function ReservoirPrice({
    price,
    onlyUSD = false,
    leftText,
}: {
    price: definitions["price"];
    onlyUSD?: boolean;
    leftText?: string;
}) {
    const priceETH = price
        ? `${price.amount?.decimal} 
${price.currency?.symbol}`
        : undefined;

    const priceUSD = price?.amount?.usd ? `$${price.amount?.usd}` : undefined;
    const priceDisplay = onlyUSD ? (
        <strong>{priceUSD}</strong>
    ) : (
        <Typography>
            <strong>{priceUSD}</strong> ({priceETH})
        </Typography>
    );
    return leftText ? (
        <Box display="flex" justifyContent="space-between">
            <Typography>{leftText}</Typography>
            {priceDisplay}
        </Box>
    ) : (
        priceDisplay
    );
}
