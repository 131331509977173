import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import {
    APIError,
    Execute,
    PlaceBidBody,
    definitions,
} from "@reservoir0x/reservoir-sdk";
import { addMonths, getUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { Address, BaseError, parseEther } from "viem";

import useReservoirClient from "src/hooks/useReservoirClient";
import useTypeWriter from "src/hooks/useTypewriter";
import useWalletClient from "src/hooks/useWalletClient";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";
import {
    Token,
    getCurrentUserBids,
    getTokenById,
    selectCurrentUserBidByTokenId,
} from "src/slices/tokenSlice";

import StyledModal from "src/components/StyledModal";
import AmountInput from "src/components/TradeModal/AmountInput";
import ReservoirPrice from "src/components/TradeModal/ReservoirPrice";

import { useAppDispatch, useAppSelector } from "src/store";
import {
    StepData,
    getMessageFromPostError,
    onProgressAction,
} from "src/utils/reservoir";

type OrderKind = Required<PlaceBidBody>["params"][0]["orderKind"];

enum ActionStep {
    SetPrice = "SetPrice",
    ApprovingCancel = "ApprovingCancel",
    Offering = "Offering",
    Complete = "Complete",
}

export default function MakeOfferButton({ token }: { token: Token }) {
    const tokenId = token.token?.tokenId;
    const topBid = token.market?.topBid;
    const floorAsk = token.market?.floorAsk;
    const currentUserBid = useAppSelector(
        selectCurrentUserBidByTokenId(Number(tokenId)),
    );

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [stepData, setStepData] = useState<StepData | null>(null);
    const [actionStep, setActionStep] = useState<ActionStep>(
        ActionStep.SetPrice,
    );
    const [ethPrice, setETHPrice] = useState(
        currentUserBid?.price?.amount?.decimal?.toString() ?? "",
    );

    const [typewriterText, setTypewriterText] = useState("");
    const { currentText: currentTypewriterText } = useTypeWriter({
        text: typewriterText,
        delay: 100,
        infinite: false,
    });

    const dispatch = useAppDispatch();
    const walletClient = useWalletClient();
    const reservoirClient = useReservoirClient();
    const isOracleOrder =
        currentUserBid?.isNativeOffChainCancellable as boolean;

    const onCloseModal = () => {
        setModalIsOpen(false);
        setStepData(null);
        setActionStep(ActionStep.SetPrice);
        setStepData(null);
        // Dispatch to have data updated
        dispatch(
            getCurrentUserBids({
                reservoirClient,
                address:
                    walletClient?.account?.address?.toLowerCase() as Address,
            }),
        );
        dispatch(
            getTokenById({
                reservoirClient,
                tokenId: Number(tokenId),
            }),
        );
    };

    useEffect(() => {
        if (
            [ActionStep.Offering, ActionStep.ApprovingCancel].includes(
                actionStep,
            ) &&
            stepData?.currentStepItem
        ) {
            const newText =
                stepData.currentStepItem.txHashes &&
                stepData.currentStepItem.txHashes.length > 0
                    ? "Waiting for transaction to be validated"
                    : "Waiting for approval...";
            setTypewriterText((prev) => (prev !== newText ? newText : prev));
        }
    }, [actionStep, stepData?.currentStepItem]);

    const openModal = async () => {
        setActionStep(ActionStep.SetPrice);
        setModalIsOpen(true);
    };

    const isUpdate = currentUserBid && isOracleOrder; // in this case we can update the bid direclty

    const placeBid = async () => {
        try {
            setIsLoading(true);
            if (currentUserBid && !isOracleOrder) {
                // if current user bid is not an oracle order, we need to cancel it before
                setActionStep(ActionStep.ApprovingCancel);
                await reservoirClient?.actions.cancelOrder({
                    ids: [currentUserBid.id],
                    wallet: walletClient,
                    onProgress: (steps: Execute["steps"]) =>
                        onProgressAction({
                            steps,
                            setStepData,
                            onComplete: () =>
                                setActionStep(ActionStep.Offering),
                        }),
                });
            }

            let options: definitions["options"] = {
                "seaport-v1.5": {
                    useOffChainCancellation: true,
                },
            };
            let orderKind: OrderKind = "seaport-v1.5";
            if (isUpdate) {
                orderKind = currentUserBid.kind as OrderKind;
                options = {
                    [currentUserBid.kind]: {
                        useOffChainCancellation: true,
                        replaceOrderId: currentUserBid.id,
                    },
                };
            }
            setActionStep((prev) =>
                prev !== ActionStep.Offering ? ActionStep.Offering : prev,
            );
            await reservoirClient?.actions.placeBid({
                bids: [
                    {
                        weiPrice: parseEther(ethPrice).toString(),
                        orderbook: "reservoir",
                        orderKind,
                        token: `${token.token?.contract}:${tokenId}`,
                        expirationTime: getUnixTime(
                            addMonths(new Date(), 1),
                        ).toString(),
                        options,
                    },
                ],
                wallet: walletClient,
                onProgress: (steps: Execute["steps"]) =>
                    onProgressAction({
                        steps,
                        setStepData,
                        onComplete: () => setActionStep(ActionStep.Complete),
                    }),
            });
        } catch (_error: unknown) {
            const error = _error as APIError | BaseError;
            const message = getMessageFromPostError(error);
            console.error("Error making an offer", message, error);
            if (message) {
                dispatch(
                    setSnackbarFeedback({
                        type: SeverityType.ERROR,
                        message,
                    }),
                );
            }
            onCloseModal();
        } finally {
            setIsLoading(false);
        }
    };

    let modalTitle = `Make an offer for "${token?.token?.name || `#${token?.token?.tokenId}`}"`;
    if (currentUserBid) {
        modalTitle = `Update your offer for "${token?.token?.name || `#${token?.token?.tokenId}`}"`;
    }
    return (
        <>
            <Button variant="contained" onClick={openModal}>
                {currentUserBid ? "Update" : "Make an offer"}
            </Button>
            {modalIsOpen && (
                <StyledModal
                    open={modalIsOpen}
                    onClose={() =>
                        ![
                            ActionStep.Offering,
                            ActionStep.ApprovingCancel,
                        ].includes(actionStep)
                            ? onCloseModal()
                            : undefined
                    }
                    aria-labelledby="make-an-offer-dialog-title"
                    aria-describedby="make-an-offer-dialog-description"
                >
                    <Stack>
                        <DialogTitle id="make-an-offer-dialog-title">
                            {modalTitle}
                        </DialogTitle>
                        <DialogContent>
                            {(floorAsk?.price ?? topBid?.price) ? (
                                <Box mb={2}>
                                    {floorAsk?.price ? (
                                        <ReservoirPrice
                                            price={floorAsk.price}
                                            leftText="Listing price:"
                                        />
                                    ) : null}
                                    {topBid?.price ? (
                                        <ReservoirPrice
                                            price={topBid.price}
                                            leftText="Highest offer:"
                                        />
                                    ) : null}
                                </Box>
                            ) : null}
                            {actionStep === ActionStep.SetPrice ? (
                                <AmountInput
                                    onChange={(e) => {
                                        setETHPrice(e.target.value);
                                    }}
                                    value={ethPrice}
                                    showUnit
                                />
                            ) : null}
                            {[
                                ActionStep.Offering,
                                ActionStep.ApprovingCancel,
                            ].includes(actionStep) ? (
                                <Typography>{currentTypewriterText}</Typography>
                            ) : null}
                            {actionStep === ActionStep.Complete ? (
                                <Typography>Offer Submitted!</Typography>
                            ) : null}
                        </DialogContent>

                        <DialogActions>
                            {actionStep === ActionStep.Complete ? (
                                <Button
                                    onClick={onCloseModal}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        onClick={onCloseModal}
                                        color="secondary"
                                        disabled={
                                            isLoading ||
                                            [
                                                ActionStep.Offering,
                                                ActionStep.ApprovingCancel,
                                            ].includes(actionStep)
                                        }
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={placeBid}
                                        autoFocus
                                        color="primary"
                                        variant="contained"
                                        disabled={
                                            isLoading ||
                                            !ethPrice.length ||
                                            Number(ethPrice) === 0 ||
                                            [
                                                ActionStep.Offering,
                                                ActionStep.ApprovingCancel,
                                            ].includes(actionStep)
                                        }
                                    >
                                        Confirm
                                    </Button>
                                </>
                            )}
                        </DialogActions>
                    </Stack>
                </StyledModal>
            )}
        </>
    );
}
