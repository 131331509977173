import { useEffect, useMemo, useState } from "react";

export default function useTypeWriter({
    text,
    delay,
    infinite,
}: {
    text: string;
    delay: number;
    infinite: boolean;
}) {
    const memoizedText = useMemo(() => text, [text]);

    const [currentText, setCurrentText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [ended, setEnded] = useState(false);

    useEffect(() => {
        setCurrentText("");
        setCurrentIndex(0);
        setEnded(true);
    }, [memoizedText]);

    useEffect(() => {
        let timeout: string | number | NodeJS.Timeout | undefined;

        if (currentIndex < text.length) {
            setEnded(false);
            timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + text[currentIndex]);
                setCurrentIndex((prevIndex) => prevIndex + 1);
            }, delay);
        } else if (infinite) {
            setCurrentIndex(0);
            setCurrentText("");
        } else if (currentIndex === text.length) {
            setEnded(true);
        }
        return () => clearTimeout(timeout);
    }, [currentIndex, delay, infinite, text]);

    return {
        currentText,
        ended,
    };
}
