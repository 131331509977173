import { Button } from "@mui/material";
import { useContext } from "react";

import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import Address from "src/components/Address";

import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch } from "src/store";

export default function ConnectButton({
    label = "Connect Wallet",
}: {
    label?: string;
}) {
    const dispatch = useAppDispatch();

    const {
        wallets,
        connectWallet,
        selectedAccount,
        handleSwitchNetwork,
        isCorrectNetwork,
    } = useContext(WalletContext);

    const handleConnect = async () => {
        if (Object.keys(wallets).length === 0) {
            dispatch(
                setSnackbarFeedback({
                    type: SeverityType.ERROR,
                    message:
                        "No Web3 wallets detected. Please install a wallet extension.",
                }),
            );
            return;
        }

        const walletRdns = Object.keys(wallets)[0]; // Connect to the first available wallet
        await connectWallet(walletRdns);
    };
    if (selectedAccount && !isCorrectNetwork) {
        return (
            <Button
                variant="contained"
                color="warning"
                onClick={handleSwitchNetwork}
            >
                Switch Network
            </Button>
        );
    }

    let content = (
        <Button variant="contained" color="primary" onClick={handleConnect}>
            {label}
        </Button>
    );
    if (selectedAccount) {
        content = <Address account={selectedAccount} />;
    }
    return content;
}
