import {
    ReservoirChain,
    ReservoirClient,
    ReservoirClientOptions,
    createClient,
    reservoirChains,
} from "@reservoir0x/reservoir-sdk";
import {
    FC,
    PropsWithChildren,
    ReactNode,
    createContext,
    useState,
} from "react";

export interface ReservoirClientProviderProps {
    children: ReactNode;
    options: ReservoirClientOptions;
}

export const ReservoirClientContext = createContext<ReservoirClient | null>(
    null,
);

function getEnvDependentChain(): ReservoirChain {
    switch (import.meta.env.VITE_CHAIN_ID) {
        case "1337":
        case "8888":
        case "84532":
            return {
                ...reservoirChains.baseSepolia,
                active: true,
            };
        case "8453":
            return {
                ...reservoirChains.base,
                active: true,
            };
        default:
            return {
                ...reservoirChains.mainnet,
                active: true,
            };
    }
}

/**
 * ReservoirClient Configuration Options
 * @param chains List of chain objects with configuration (id, name, baseApiUrl, paymentTokens and if it's the default)
 * @param source Used to manually override the source domain used to attribute local orders
 * @param automatedRoyalties If true, royalties will be automatically included, defaults to true. Only relevant for creating orders.
 * @param marketplaceFees A list of fee strings representing a recipient and the fee in BPS delimited by a colon: ["0xabc:100"] used when creating an order (listing or bid)
 * @param normalizeRoyalties Normalize orders that don't have royalties by apply royalties on top of them
 * @param bountyReferrer Referrer address to collect bounties when filling orders (applies to zora, manifold, etc)
 * @param logLevel Log level from 0-4, the higher the more verbose.
 * @param maxPollingAttemptsBeforeTimeout The maximum number of attempts the synced api is polled before timing out. The api is polled every 5 secs (default is 30)
 */

export const ReservoirClientProvider: FC<PropsWithChildren> = ({
    children,
}: PropsWithChildren) => {
    let marketplaceFees;
    if (
        import.meta.env.VITE_MARKETPLACE_FEES_ADDRESS?.length &&
        import.meta.env.VITE_MARKETPLACE_FEES_BPS?.length
    ) {
        marketplaceFees = [
            `${import.meta.env.VITE_MARKETPLACE_FEES_ADDRESS}:${import.meta.env.VITE_MARKETPLACE_FEES_BPS}`,
        ];
    }
    const [clientContext] = useState<ReservoirClient | null>(
        createClient({
            chains: [getEnvDependentChain()],
            marketplaceFees,
            apiKey: import.meta.env.VITE_RESERVOIR_API_KEY,
        }),
    );

    return (
        <ReservoirClientContext.Provider value={clientContext}>
            {children}
        </ReservoirClientContext.Provider>
    );
};
