import Link from "@mui/material/Link";
import { Link as ReactRouterLink } from "react-router-dom";

import FullscreenMessage from "src/components/FullscreenMessage";

import { PATHS } from "src/constants";

const Error = () => {
    return (
        <FullscreenMessage>
            Sorry, we encountered an error. Do you have all your web3 gear ready
            and setup?
            <br />
            <br />
            <Link component={ReactRouterLink} to={PATHS.LANDING}>
                Go back somewhere safe
            </Link>
        </FullscreenMessage>
    );
};

export default Error;
