import { useContext, useEffect, useMemo } from "react";
import {
    Account,
    Chain,
    CustomTransport,
    WalletClient,
    createWalletClient,
    custom,
} from "viem";
import { mainnet } from "viem/chains";

import { getChainByStringID, getEnvDependentChain } from "src/chains";
import { WalletContext } from "src/contexts/WalletContext";

const walletClients: Record<
    string,
    WalletClient<CustomTransport, Chain, Account>
> = {};

const useWalletClient = (chainId?: string) => {
    const { selectedWallet, selectedAccount } = useContext(WalletContext);
    const chain = useMemo(() => {
        let memoizedChain;
        if (!chainId) {
            memoizedChain = getEnvDependentChain();
        } else {
            memoizedChain = getChainByStringID(chainId && `${chainId}`);
        }

        if (!memoizedChain) {
            memoizedChain = mainnet;
        }

        return memoizedChain;
    }, [chainId]);

    const instanceKey = chain.id;
    if (
        !walletClients[instanceKey] &&
        selectedWallet?.provider &&
        selectedAccount
    ) {
        walletClients[instanceKey] = createWalletClient({
            account: selectedAccount,
            chain,
            transport: custom(selectedWallet.provider),
        });
    }

    useEffect(() => {
        if (selectedWallet?.provider && selectedAccount) {
            walletClients[instanceKey] = createWalletClient({
                account: selectedAccount,
                chain,
                transport: custom(selectedWallet.provider),
            });
        }
    }, [selectedAccount, selectedWallet?.provider, chain, instanceKey]);

    return walletClients[instanceKey];
};

export default useWalletClient;
