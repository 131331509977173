import { differenceInHours } from "date-fns";
import { useEffect, useState } from "react";

import {
    getNextWordIntroductionData,
    selectContractSettingsStatuses,
    selectNextWordIntroductionData,
} from "src/slices/contractSettingsSlice";

import { useAppDispatch, useAppSelector } from "src/store";
import { formatRemainingTime } from "src/utils/formatDate";

export default function NextWordIntroductionCountdown() {
    const [timeRemainingInMs, setTimeRemainingInMs] = useState<number | null>(
        null,
    );
    const dispatch = useAppDispatch();
    const nextWordIntroductionData = useAppSelector(
        selectNextWordIntroductionData,
    );
    const statuses = useAppSelector(selectContractSettingsStatuses);

    useEffect(() => {
        if (statuses.getNextWordIntroductionData === "idle") {
            dispatch(getNextWordIntroductionData());
        }
    }, [dispatch, statuses.getNextWordIntroductionData]);

    useEffect(() => {
        if (timeRemainingInMs && timeRemainingInMs < 0) {
            // If the last next word introduction data is passed, we fetch updated date each timeRemainingInMs update so each second
            dispatch(getNextWordIntroductionData());
        }
    }, [dispatch, timeRemainingInMs]);

    useEffect(() => {
        if (nextWordIntroductionData?.date) {
            const updateRemainingTime = () => {
                const remainingTime =
                    new Date(nextWordIntroductionData.date).getTime() -
                    Math.floor(new Date().getTime());
                setTimeRemainingInMs(remainingTime);
                return remainingTime;
            };

            const interval = setInterval(updateRemainingTime, 1_000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [nextWordIntroductionData?.date]);

    return (
        nextWordIntroductionData &&
        ` Next drop ${formatRemainingTime({
            prefix: "in",
            dateString: nextWordIntroductionData.date,
            shouldIncludeSeconds:
                differenceInHours(
                    new Date(nextWordIntroductionData.date),
                    new Date(),
                ) < 1,
        })}`
    );
}
