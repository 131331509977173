import { ReservoirClient, paths, setParams } from "@reservoir0x/reservoir-sdk";
import axios from "axios";
import rateLimit from "axios-rate-limit";
import { version } from "package.json";
import { Address } from "viem";

const reservoirAxiosInstance = rateLimit(axios.create(), {
    maxRequests: 1,
    perMilliseconds: 1_000,
});

// object to store ongoing requests cancel tokens
const pendingRequests = new Map();

// next we set up the Request Interceptor, this logic triggers
// before each request that we send
reservoirAxiosInstance.interceptors.request.use(
    (config) => {
        // generate an identifier for each request
        const requestIdentifier = `${config.url}_${config.method}`;

        // check if there is already a pending request with the same identifier
        if (pendingRequests.has(requestIdentifier)) {
            const cancelTokenSource = pendingRequests.get(requestIdentifier);
            // cancel the previous request
            cancelTokenSource.cancel("Cancelled due to new request");
        }

        // create a new CancelToken
        const newCancelTokenSource = axios.CancelToken.source();
        config.cancelToken = newCancelTokenSource.token;

        // store the new cancel token source in the map
        pendingRequests.set(requestIdentifier, newCancelTokenSource);

        return config;
    },
    (error) => {
        // return the error if the request fails
        return Promise.reject(error);
    },
);

// here we set up the Response Interceptor, this logic triggers
// before each response from the server comes
reservoirAxiosInstance.interceptors.response.use(
    (response) => {
        // remove completed request from pending map
        const requestIdentifier = `${response.config.url}_${response.config.method}`;
        pendingRequests.delete(requestIdentifier);
        return response;
    },
    (error) => {
        // remove failed request from pending map
        if (error.config) {
            const requestIdentifier = `${error.config.url}_${error.config.method}`;
            pendingRequests.delete(requestIdentifier);
        }
        return Promise.reject(error);
    },
);

export const defaultHeaders = (
    apiKey?: string | null,
    clientVersion?: string | null,
) => {
    const headers: HeadersInit = {
        "x-rkui-version": version,
    };
    if (apiKey) {
        headers["x-api-key"] = apiKey;
    }
    if (clientVersion) {
        headers["x-rkc-version"] = clientVersion;
    }
    return headers;
};

type DefaultParams = {
    reservoirClient?: ReservoirClient | null;
    chainId?: number;
};

// https://docs.reservoir.tools/reference/gettokensv7
type TokenDetailsResponse =
    paths["/tokens/v7"]["get"]["responses"]["200"]["schema"];

type TokensQuery = paths["/tokens/v7"]["get"]["parameters"]["query"];

export const getTokens = async ({
    reservoirClient,
    chainId,
    options,
}: DefaultParams & { options?: TokensQuery | false }) => {
    const chain =
        chainId !== undefined
            ? reservoirClient?.chains.find((chain) => chain.id === chainId)
            : reservoirClient?.currentChain();
    const url = new URL(`${chain?.baseApiUrl}/tokens/v7`);
    const query: TokensQuery = { ...options };
    if (
        query.normalizeRoyalties === undefined &&
        reservoirClient?.normalizeRoyalties !== undefined
    ) {
        query.normalizeRoyalties = reservoirClient.normalizeRoyalties;
    }

    setParams(url, query);
    const headers = defaultHeaders(
        reservoirClient?.apiKey,
        reservoirClient?.version,
    );
    return reservoirAxiosInstance<TokenDetailsResponse>(url.href, {
        headers,
    })
        .then((res) => {
            if (res.headers?.["deprecation"] === "true") {
                console.warn(
                    `Warning: API ${res.config.url} is deprecated. Stability and performance may be affected.`,
                );
            }

            return res.data;
        })
        .catch((e) => {
            throw e;
        });
};

export type UserTokenResponse =
    paths["/users/{user}/tokens/v10"]["get"]["responses"]["200"]["schema"];

type UserTokenQuery =
    paths["/users/{user}/tokens/v10"]["get"]["parameters"]["query"];

export const getUserTokens = async ({
    reservoirClient,
    chainId,
    options,
    address,
}: DefaultParams & { options?: UserTokenQuery | false; address?: Address }) => {
    const chain =
        chainId !== undefined
            ? reservoirClient?.chains.find((chain) => chain.id === chainId)
            : reservoirClient?.currentChain();
    const url = new URL(`${chain?.baseApiUrl}/users/${address}/tokens/v10`);
    const query: UserTokenQuery = { ...options };
    if (
        query.normalizeRoyalties === undefined &&
        reservoirClient?.normalizeRoyalties !== undefined
    ) {
        query.normalizeRoyalties = reservoirClient.normalizeRoyalties;
    }

    setParams(url, query);
    const headers = defaultHeaders(
        reservoirClient?.apiKey,
        reservoirClient?.version,
    );
    return reservoirAxiosInstance<UserTokenResponse>(url.href, {
        headers,
    })
        .then((res) => {
            if (res.headers?.["deprecation"] === "true") {
                console.warn(
                    `Warning: API ${res.config.url} is deprecated. Stability and performance may be affected.`,
                );
            }

            return res.data;
        })
        .catch((e) => {
            throw e;
        });
};

// https://docs.reservoir.tools/reference/getordersasksv5
type Asks = paths["/orders/asks/v5"]["get"]["responses"]["200"]["schema"];
type AsksQuery = paths["/orders/asks/v5"]["get"]["parameters"]["query"];

export const getListings = async ({
    reservoirClient,
    chainId,
    options,
}: DefaultParams & { options?: AsksQuery | false }) => {
    const chain =
        chainId !== undefined
            ? reservoirClient?.chains.find((chain) => chain.id === chainId)
            : reservoirClient?.currentChain();
    const url = new URL(`${chain?.baseApiUrl}/orders/asks/v5`);
    const query: AsksQuery = { ...options };
    if (
        query.normalizeRoyalties === undefined &&
        reservoirClient?.normalizeRoyalties !== undefined
    ) {
        query.normalizeRoyalties = reservoirClient.normalizeRoyalties;
    }

    setParams(url, query);
    const headers = defaultHeaders(
        reservoirClient?.apiKey,
        reservoirClient?.version,
    );
    return reservoirAxiosInstance<Asks>(url.href, {
        headers,
    })
        .then((res) => {
            if (res.headers?.["deprecation"] === "true") {
                console.warn(
                    `Warning: API ${res.config.url} is deprecated. Stability and performance may be affected.`,
                );
            }

            return res.data;
        })
        .catch((e) => {
            throw e;
        });
};

// https://docs.reservoir.tools/reference/getordersasksv5
export type UserBids =
    paths["/users/{user}/bids/v1"]["get"]["responses"]["200"]["schema"];
type UserBidsQuery =
    paths["/users/{user}/bids/v1"]["get"]["parameters"]["query"];

export const getUserBids = async ({
    address,
    reservoirClient,
    chainId,
    options,
}: DefaultParams & { options?: UserBidsQuery; address?: Address }) => {
    const chain =
        chainId !== undefined
            ? reservoirClient?.chains.find((chain) => chain.id === chainId)
            : reservoirClient?.currentChain();
    const url = new URL(`${chain?.baseApiUrl || ""}/users/${address}/bids/v1`);
    const query: AsksQuery = { ...options };
    if (
        query.normalizeRoyalties === undefined &&
        reservoirClient?.normalizeRoyalties !== undefined
    ) {
        query.normalizeRoyalties = reservoirClient.normalizeRoyalties;
    }

    setParams(url, query);
    const headers = defaultHeaders(
        reservoirClient?.apiKey,
        reservoirClient?.version,
    );
    return reservoirAxiosInstance<UserBids>(url.href, {
        headers,
    })
        .then((res) => {
            if (res.headers?.["deprecation"] === "true") {
                console.warn(
                    `Warning: API ${res.config.url} is deprecated. Stability and performance may be affected.`,
                );
            }

            return res.data;
        })
        .catch((e) => {
            throw e;
        });
};

export type TokenBids =
    paths["/tokens/{token}/bids/v1"]["get"]["responses"]["200"]["schema"];
type TokenBidsQuery =
    paths["/tokens/{token}/bids/v1"]["get"]["parameters"]["query"];

export const getTokenBids = async ({
    token,
    reservoirClient,
    chainId,
    options,
}: DefaultParams & { options?: TokenBidsQuery; token?: string }) => {
    const chain =
        chainId !== undefined
            ? reservoirClient?.chains.find((chain) => chain.id === chainId)
            : reservoirClient?.currentChain();
    const url = new URL(`${chain?.baseApiUrl || ""}/tokens/${token}/bids/v1`);
    const query: AsksQuery = { ...options };
    if (
        query.normalizeRoyalties === undefined &&
        reservoirClient?.normalizeRoyalties !== undefined
    ) {
        query.normalizeRoyalties = reservoirClient.normalizeRoyalties;
    }

    setParams(url, query);
    const headers = defaultHeaders(
        reservoirClient?.apiKey,
        reservoirClient?.version,
    );
    return reservoirAxiosInstance<TokenBids>(url.href, {
        headers,
    })
        .then((res) => {
            if (res.headers?.["deprecation"] === "true") {
                console.warn(
                    `Warning: API ${res.config.url} is deprecated. Stability and performance may be affected.`,
                );
            }

            return res.data;
        })
        .catch((e) => {
            throw e;
        });
};
