import { Box, CircularProgress, Typography } from "@mui/material";

export default function FullscreenMessage({
    children,
    withLoader = false,
}: {
    children: React.ReactNode;
    withLoader?: boolean;
}) {
    return (
        <Box
            sx={{
                display: "flex",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
            }}
        >
            {withLoader && <CircularProgress sx={{ my: 2 }} />}
            <Typography>{children}</Typography>
        </Box>
    );
}
