import Link from "@mui/material/Link";
import { Link as ReactRouterLink } from "react-router-dom";

import FullscreenMessage from "src/components/FullscreenMessage";

import { PATHS } from "src/constants";

const NotFound = () => {
    return (
        <FullscreenMessage>
            This page does not exist 👻 <br /> ... or the devs f***ed up
            (again).
            <br />
            At least they know now that not all links should be hardcoded.
            <br />
            <br />
            <Link component={ReactRouterLink} to={PATHS.LANDING}>
                Go back somewhere safe
            </Link>
        </FullscreenMessage>
    );
};

export default NotFound;
