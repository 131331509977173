import {
    InputAdornment,
    TextField,
    TextFieldProps,
    Typography,
} from "@mui/material";

function AmountInput({
    showUnit = false,
    unit = "ETH",
    onChange,
    ...props
}: TextFieldProps & {
    showUnit?: boolean;
    unit?: string;
    showMax?: boolean;
    onMaxClick?: () => void;
}) {
    return (
        <div>
            <TextField
                placeholder={`Enter ${unit} amount`}
                slotProps={{
                    input: {
                        endAdornment: (
                            <InputAdornment position="end">
                                {showUnit && (
                                    <Typography color="secondary">
                                        {unit}
                                    </Typography>
                                )}
                                &nbsp;
                            </InputAdornment>
                        ),
                    },
                }}
                type="number"
                fullWidth
                {...props}
                onChange={onChange}
                sx={{
                    // remove up and down arrows of the input type number
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                            display: "none",
                        },
                    "& input[type=number]": {
                        MozAppearance: "textfield",
                    },
                    ...props.sx,
                }}
                name="amount-input"
                id="amount-input"
            />
        </div>
    );
}

export default AmountInput;
