import { useCallback, useEffect, useState } from "react";

import {
    selectContractSettingsStatuses,
    selectWordsOpenToBeBought,
} from "src/slices/contractSettingsSlice";
import { selectWordsByPage } from "src/slices/wordSlice";

import { useAppSelector } from "src/store";

export default function useSpinningWheel() {
    // getWordsOpenToBeBought call is made in BuyWord component
    const wordsOpenToBeBought = useAppSelector(selectWordsOpenToBeBought);
    const wordsToFill = useAppSelector(selectWordsByPage(1));
    const statuses = useAppSelector(selectContractSettingsStatuses);
    const [showingWordIndex, setShowingWordIndex] = useState(0);
    const [spinning, setSpinning] = useState(true);

    const setShowingWord = useCallback(
        (word: string) => {
            setShowingWordIndex(wordsOpenToBeBought.indexOf(word));
        },
        [wordsOpenToBeBought],
    );

    const lengthOfWordsToSpin =
        wordsOpenToBeBought.length || wordsToFill.length;

    useEffect(() => {
        if (lengthOfWordsToSpin > 0 && spinning) {
            const interv = setInterval(() => {
                setShowingWordIndex((prev) => (prev + 1) % lengthOfWordsToSpin);
            }, 0.1 * 1_000);
            return () => clearInterval(interv);
        }
    }, [lengthOfWordsToSpin, spinning]);

    return {
        showingWordIndex,
        setShowingWordIndex,
        setShowingWord,
        spinning,
        setSpinning,
        status: statuses.getWordsOpenToBeBought,
        wordsToSpin: wordsOpenToBeBought,
    };
}
