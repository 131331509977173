import { Stack } from "@mui/material";

import { Token } from "src/slices/tokenSlice";

import SellToContractInfoAndButton from "src/components/SellToContractInfoAndButton";
import ListForSaleButton from "src/components/TradeModal/ListForSaleButton";

const TradeModalSellContent = ({
    token,
    setDisableCloseModal,
}: {
    token: Token;
    setDisableCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const tokenId = token.token?.tokenId;
    const listingId = token?.market?.floorAsk?.id;

    return (
        <Stack pt={2} pb={2} gap={2}>
            {tokenId && (
                <SellToContractInfoAndButton
                    tokenId={Number(tokenId)}
                    setDisableCloseModal={setDisableCloseModal}
                />
            )}
            {!listingId && <ListForSaleButton token={token} />}
        </Stack>
    );
};

export default TradeModalSellContent;
