import axios from "axios";
import { objectToCamel, objectToSnake } from "ts-case-convert";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";

import { type BackendWord } from "src/slices/wordSlice";

import { TOP_25_WORDS_COUNT } from "src/constants";

const apiAxiosInstance = axios.create({
    baseURL: `${import.meta.env.VITE_BACKEND_HOST}/api`,
});

export async function isBackendAvailable() {
    try {
        const response = await apiAxiosInstance.get(`/`);
        return response.data.success;
    } catch (error) {
        console.error(error);
        return false;
    }
}

type GetWordsOrderBy =
    | "text"
    | "price"
    | "latest_update_frequency"
    | "current_frequency"
    | "variation_one_hour"
    | "variation_twenty_four_hours"
    | "variation_seven_days";

export type GetWordsParams = {
    page: number;
    rowsPerPage: number;
    term?: string | null;
    orderByKey: GetWordsOrderBy;
    orderByDirection: "asc" | "desc";
    words?: string[] | null;
};

export async function getWords({
    page = 1,
    rowsPerPage = TOP_25_WORDS_COUNT,
    term = null,
    orderByKey = "current_frequency",
    orderByDirection = "desc",
    words = [],
}: GetWordsParams) {
    const response = await apiAxiosInstance.get<{
        items: BackendWord[];
        total: number;
        page: number;
        rows_per_page: number;
        total_pages: number;
        total_words_in_game: number;
    }>(`/words`, {
        params: objectToSnake({
            page,
            rowsPerPage,
            term,
            orderByKey,
            orderByDirection,
            words: words && words?.length > 0 ? words?.join(",") : null,
        }),
    });
    return objectToCamel(response.data);
}

export async function getWordFromText(wordText: string) {
    const response = await apiAxiosInstance.get<BackendWord>(
        `/words/${wordText}`,
    );
    return objectToCamel(response.data);
}

export async function getWordChartData({ wordId }: { wordId: number }) {
    const response = await apiAxiosInstance.get<{
        data: Array<{
            weighted_relative_frequency: number;
            date: string;
        }>;
    }>(`/words/${wordId}/chart`);

    return objectToCamel(response.data);
}

export type TwitterListUser = ObjectToCamel<{
    id: string;
    name: string;
    username: string;
    profile_image_url: string;
}>;

export async function getTwitterListUsers() {
    const response =
        await apiAxiosInstance.get<TwitterListUser[]>(`/twitter_list_users`);
    return objectToCamel(response.data);
}

export async function getChartsData({ wordIds }: { wordIds: number[] }) {
    const response = await apiAxiosInstance.get<{
        data: Array<{
            word_id: number;
            text: string;
            frequencies: Array<{
                weighted_relative_frequency: number;
                date: string;
            }>;
        }>;
    }>(`/charts`, {
        params: objectToSnake({
            wordIds: wordIds?.join(",") ?? null,
        }),
    });

    return objectToCamel(response.data);
}

export async function getCurrencyRate() {
    const response = await apiAxiosInstance.get<{ rate: number }>(
        `/currency-rate`,
    );

    return response.data["rate"];
}

export async function getNextWordIntroductionData() {
    const response = await apiAxiosInstance.get<{
        next_word_introduction_date: string;
        next_word_introduction_number: number;
    }>(`/next-word-introduction`);

    return {
        date: response.data["next_word_introduction_date"],
        wordNumbers: response.data["next_word_introduction_number"],
    };
}
