import { Box, Modal, ModalProps, useMediaQuery, useTheme } from "@mui/material";

const baseModalStyle = {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "calc(100vw - 2rem)",
    maxWidth: 500,
    bgcolor: "background.paper",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "secondary.main",
    borderRadius: 3,
    boxShadow: 24,
    px: 2,
    py: 3,
    textAlign: "center",
    outline: "none",
};

export default function StyledModal({ children, ...props }: ModalProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const modalStyle = {
        ...baseModalStyle,
        ...(isMobile
            ? {
                  top: 0,
                  transform: "translate(-50%, 20%)",
                  height: "70%",
                  maxHeight: "100vh",
                  overflowY: "auto",
              }
            : {
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  maxHeight: "90vh",
                  overflowY: "auto",
              }),
    };

    return (
        <Modal {...props}>
            <Box sx={modalStyle}>{children}</Box>
        </Modal>
    );
}
