import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import { APIError, Execute } from "@reservoir0x/reservoir-sdk";
import { AxiosError } from "axios";
import { formatDistanceToNowStrict } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { BaseError } from "viem";

import useReservoirClient from "src/hooks/useReservoirClient";
import useTypeWriter from "src/hooks/useTypewriter";
import useWalletClient from "src/hooks/useWalletClient";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";
import {
    Token,
    getTokenById,
    selectCurrentUserBidByTokenId,
} from "src/slices/tokenSlice";

import StyledModal from "src/components/StyledModal";
import MakeOfferButton from "src/components/TradeModal/MakeOfferButton";
import ReservoirPrice from "src/components/TradeModal/ReservoirPrice";

import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";
import {
    StepData,
    getMessageFromPostError,
    onProgressAction,
} from "src/utils/reservoir";

enum ActionStep {
    Buy = "Buy",
    Approving = "Approving",
    Complete = "Complete",
}

const TradeModalBuyContent = ({ token }: { token: Token }) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [stepData, setStepData] = useState<StepData | null>(null);
    const [actionStep, setActionStep] = useState<ActionStep>(ActionStep.Buy);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [typewriterText, setTypewriterText] = useState("");
    const { currentText: currentTypewriterText } = useTypeWriter({
        text: typewriterText,
        delay: 100,
        infinite: false,
    });
    const { selectedAccount } = useContext(WalletContext);

    const walletClient = useWalletClient();
    const reservoirClient = useReservoirClient();
    const tokenId = token.token?.tokenId;
    const tokenIsListed = Boolean(token?.market?.floorAsk?.maker);
    const floorAsk = token.market?.floorAsk;
    const currentUserBid = useAppSelector(
        selectCurrentUserBidByTokenId(Number(tokenId)),
    );
    const tokenOwer = token?.token?.owner?.toLowerCase();

    const tokenIsOwnedByCurrentUser =
        tokenOwer === selectedAccount?.toLowerCase();

    useEffect(() => {
        if (actionStep === ActionStep.Approving && stepData?.currentStepItem) {
            const newText =
                stepData.currentStepItem.txHashes &&
                stepData.currentStepItem.txHashes.length > 0
                    ? "Waiting for transaction to be validated"
                    : "Waiting for approval...";
            setTypewriterText((prev) => (prev !== newText ? newText : prev));
        }
    }, [actionStep, stepData?.currentStepItem]);

    const onCloseModal = () => {
        setModalIsOpen(false);
        setStepData(null);
        setActionStep(ActionStep.Buy);
        setStepData(null);
        // Dispatch to have data updated
        dispatch(
            getTokenById({
                reservoirClient,
                tokenId: Number(tokenId),
            }),
        );
    };

    const buyToken = async () => {
        try {
            setIsLoading(true);
            setActionStep(ActionStep.Approving);
            setModalIsOpen(true);
            await reservoirClient?.actions.buyToken({
                items: [
                    {
                        token: `${token.token?.contract}:${token.token?.tokenId}`,
                        quantity: 1,
                    },
                ],
                wallet: walletClient,
                onProgress: (steps: Execute["steps"]) =>
                    onProgressAction({
                        steps,
                        setStepData,
                        onComplete: () => setActionStep(ActionStep.Complete),
                    }),
            });
        } catch (_error: unknown) {
            const error = _error as APIError | BaseError | AxiosError;
            const message = getMessageFromPostError(error);
            console.error("Error buying token", message, error);
            if (message) {
                dispatch(
                    setSnackbarFeedback({
                        type: SeverityType.ERROR,
                        message,
                    }),
                );
            }
            onCloseModal();
        } finally {
            setIsLoading(false);
        }
    };

    const priceUSD = token.market?.floorAsk?.price?.amount?.usd
        ? `$${token.market.floorAsk.price.amount?.usd}`
        : undefined;

    return (
        <Stack pt={2} pb={2} gap={2}>
            {floorAsk?.price ? (
                <Box>
                    <ReservoirPrice
                        price={floorAsk.price}
                        leftText="Listing price:"
                    />
                    {floorAsk.validUntil && (
                        <Box display="flex" justifyContent="space-between">
                            <Typography>Expiration:</Typography>
                            <Typography fontWeight="bold">
                                {formatDistanceToNowStrict(
                                    new Date(floorAsk.validUntil * 1_000),
                                    { addSuffix: true },
                                )}
                            </Typography>
                        </Box>
                    )}
                </Box>
            ) : null}
            <Stack gap={2}>
                {tokenIsListed ? (
                    <Button
                        variant="contained"
                        onClick={buyToken}
                        disabled={isLoading}
                    >
                        Buy for&nbsp;<strong>{priceUSD}</strong>
                    </Button>
                ) : (
                    <Typography>
                        The owner of this word has not yet put it up for sale
                    </Typography>
                )}
                {!currentUserBid && !tokenIsOwnedByCurrentUser && (
                    <MakeOfferButton token={token} />
                )}
            </Stack>
            {modalIsOpen && (
                <StyledModal
                    open={modalIsOpen}
                    onClose={() =>
                        actionStep !== ActionStep.Approving
                            ? onCloseModal()
                            : undefined
                    }
                    aria-labelledby="buy-listed-token-dialog-title"
                    aria-describedby="buy-listed-token-dialog-description"
                >
                    <Stack>
                        <DialogTitle id="buy-listed-token-dialog-title">
                            Buying "
                            {token?.token?.name || `#${token?.token?.tokenId}`}"
                        </DialogTitle>
                        <DialogContent>
                            {actionStep === ActionStep.Approving ? (
                                <Typography>{currentTypewriterText}</Typography>
                            ) : null}
                            {actionStep === ActionStep.Complete ? (
                                <Typography>
                                    Congrats! Purchase was successful.
                                </Typography>
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            {actionStep === ActionStep.Complete ? (
                                <Button
                                    onClick={onCloseModal}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            ) : null}
                        </DialogActions>
                    </Stack>
                </StyledModal>
            )}
        </Stack>
    );
};

export default TradeModalBuyContent;
