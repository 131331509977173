import { formatEther, formatGwei } from "viem";

import {
    CURRENCY_SYMBOL,
    FOUR_DECIMALS_ETH_IN_WEI,
    ONE_GWEI_IN_WEI,
} from "src/constants";

const convertScientificNotationNumber = (value: number) => {
    if (value >= 1) {
        return `${value}`;
    }

    const decimalsPart =
        value?.toString()?.split(".")?.[1]?.split("e-")?.[0] || "";
    const eDecimals = Number(value?.toString()?.split("e-")?.[1]) || 0;
    const countOfDecimals = decimalsPart.length + eDecimals;

    return Number(value).toFixed(countOfDecimals);
};

const toReadableString = (
    amount: number,
    prefix = "",
    suffix = "",
    toFixed?: number,
) => {
    const isVerySmallNumber =
        toFixed && Number(amount) < Number(`1e-${toFixed}`);

    if (isVerySmallNumber) {
        const minValue = Number(`1e-${toFixed}`).toFixed(toFixed);

        // We want a different display for very small numbers, let's return early
        return `< ${prefix}${minValue}${suffix ? ` ${suffix}` : ""}`;
    }

    let stringContent;
    if (typeof toFixed === "number") {
        const fixedAmount = Number(Number(amount).toFixed(toFixed)); // Convert the amount to a fixed number of decimals; the outer Number() removes any leading zeros intentionally
        stringContent = convertScientificNotationNumber(fixedAmount);
    } else {
        stringContent = amount.toLocaleString(undefined, {
            useGrouping: false,
            maximumFractionDigits: 18,
        });
    }

    // Default case: convert scientific notation to plain text
    return `${prefix}${stringContent}${suffix ? ` ${suffix}` : ""}`;
};

type FormatCurrencyParams = {
    valueToConvert: bigint; // Should always be in WEI
    currencySymbol: CURRENCY_SYMBOL.USD | CURRENCY_SYMBOL.ETH;
    toFixed?: number;
    rate: number | null;
};
export function formatCurrency({
    valueToConvert,
    currencySymbol,
    toFixed,
    rate,
}: FormatCurrencyParams): string {
    const unitToDisplayAsPrefix =
        currencySymbol === CURRENCY_SYMBOL.USD ? "$" : "";
    let unitToDisplayAsSuffix =
        currencySymbol === CURRENCY_SYMBOL.USD ? "" : currencySymbol;

    if (valueToConvert == null || isNaN(Number(valueToConvert))) {
        console.error(
            `formatCurrency: value should be a number, number in a string or a bigint - Received: ${valueToConvert}`,
        );

        return "???";
    }

    let valueToReturn: number;

    if (currencySymbol === CURRENCY_SYMBOL.ETH) {
        try {
            if (
                valueToConvert > BigInt(ONE_GWEI_IN_WEI) &&
                valueToConvert < BigInt(FOUR_DECIMALS_ETH_IN_WEI)
            ) {
                valueToReturn = Number(formatGwei(valueToConvert));
                unitToDisplayAsSuffix = CURRENCY_SYMBOL.GWEI;
                toFixed = toFixed && toFixed > 2 ? 2 : toFixed; // We force max 2 decimals for GWEI
            } else if (valueToConvert < BigInt(ONE_GWEI_IN_WEI)) {
                valueToReturn = Number(valueToConvert.toString());
                unitToDisplayAsSuffix = CURRENCY_SYMBOL.WEI;
                toFixed = 0; // No decimal for WEI
            } else {
                valueToReturn = Number(formatEther(valueToConvert));
            }
        } catch (error) {
            throw new Error(
                `Error converting Wei to ETH: ${(error as Error)?.message}`,
            );
        }
    } else if (currencySymbol === CURRENCY_SYMBOL.USD) {
        if (!rate) {
            throw new Error(
                `formatCurrency: need to convert in USD but rate is not defined`,
            );
        }
        valueToReturn = Number(valueToConvert) * rate * 10e-19; // number
    } else {
        throw new Error(
            `formatCurrency: unsupported conversion - to ${currencySymbol}`,
        );
    }

    return toReadableString(
        valueToReturn,
        unitToDisplayAsPrefix,
        unitToDisplayAsSuffix,
        toFixed,
    );
}
