import { Box, Stack, Typography, useTheme } from "@mui/material";

import { selectWordsByPage } from "src/slices/wordSlice";

import { useAppSelector } from "src/store";

const FONT_SIZE = 30;
const SPACING_SIZE = FONT_SIZE * 1.5;

export default function SpinningWheel({
    wordsToSpin,
    showingWordIndex,
}: {
    wordsToSpin: string[];
    showingWordIndex: number;
}) {
    const theme = useTheme();
    const allWords = useAppSelector(selectWordsByPage(1));
    const wordsToShow =
        wordsToSpin.length > 0 ? wordsToSpin : allWords.map(({ text }) => text);

    return (
        <Stack sx={{ position: "relative" }}>
            <Stack
                sx={{
                    height: 3 * SPACING_SIZE,
                    alignItems: "center",
                    overflow: "hidden",
                    "::before": {
                        content: '""',
                        position: "absolute",
                        display: "block",
                        borderLeft: "15px solid transparent",
                        borderRight: "15px solid transparent",
                        borderBottom: `15px solid ${theme.palette.primary.main}`,
                        left: -50,
                        top: "calc(50% - 2.5px)",
                        transform: "rotate(90deg)",
                    },
                    "::after": {
                        content: '""',
                        position: "absolute",
                        display: "block",
                        borderLeft: "15px solid transparent",
                        borderRight: "15px solid transparent",
                        borderTop: `15px solid ${theme.palette.primary.main}`,
                        right: -50,
                        top: "calc(50% - 2.5px)",
                        transform: "rotate(90deg)",
                    },
                }}
            >
                {wordsToSpin.length <= 1 && (
                    <Box sx={{ height: SPACING_SIZE }}></Box>
                )}
                {wordsToShow.map((text, index) => (
                    <Typography
                        key={text}
                        component="div"
                        fontSize={FONT_SIZE}
                        sx={{
                            display: "block",
                            transition: `transform 0.01s linear`,
                        }}
                        style={{
                            transform: `translateY(-${(showingWordIndex - 1) * SPACING_SIZE}px) scale(${showingWordIndex !== index && wordsToSpin.length > 1 ? "0.5" : "1"})`,
                        }}
                        color={
                            showingWordIndex === index ? "primary" : "secondary"
                        }
                    >
                        {text}
                    </Typography>
                ))}
                {wordsToSpin.length <= 1 && (
                    <Box sx={{ height: SPACING_SIZE }}></Box>
                )}
            </Stack>
        </Stack>
    );
}
