import ErrorIcon from "@mui/icons-material/Error";
import PauseIcon from "@mui/icons-material/Pause";
import SyncIcon from "@mui/icons-material/Sync";
import { Stack, Typography, useTheme } from "@mui/material";
import { useContext, useEffect } from "react";

import {
    ContractState,
    getContractFrequencyUpdateCounter,
    getCurrentContractState,
    selectContractSettingsStatuses,
    selectCurrentContractState,
} from "src/slices/contractSettingsSlice";

import { ContractContext } from "src/contexts/ContractsContext";
import { useAppDispatch, useAppSelector } from "src/store";

function RoundDetails() {
    const dispatch = useAppDispatch();
    const contractState = useAppSelector(selectCurrentContractState);
    const statuses = useAppSelector(selectContractSettingsStatuses);
    const { contract } = useContext(ContractContext);
    const theme = useTheme();

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (contract?.address) {
            dispatch(getCurrentContractState({ contract }));
            dispatch(getContractFrequencyUpdateCounter({ contract }));

            interval = setInterval(
                () => {
                    dispatch(getCurrentContractState({ contract }));
                },
                5_000, // fetch every 5 seconds in case of a round change
            );
        }

        return () => {
            clearInterval(interval);
        };
    }, [contract, dispatch]);

    if (contractState === ContractState.OPEN) {
        return;
    }

    let Icon;
    let bgColor;
    let text;

    if (statuses.getCurrentContractState == "loading") {
        Icon = SyncIcon;
        text = "Refreshing contract status...";
        bgColor = theme.palette.secondary.main;
    } else if (statuses.getCurrentContractState == "failed") {
        Icon = ErrorIcon;
        text = "Error fetching current round";
        bgColor = theme.palette.error.main;
    } else if (statuses.getCurrentContractState == "succeeded") {
        Icon = PauseIcon;
        bgColor = theme.palette.warning.main;
        if (contractState === ContractState.FREQUENCY_UPDATE) {
            text =
                "Contract is paused as the system is receiving new frequencies";
        }
        if (contractState === ContractState.NEW_WORDS_ADDITION) {
            text =
                "Contract is paused as the system is inserting new words in the game";
        }
        if (contractState === ContractState.SCALING_UP) {
            text = "Contract is paused as the system is scaling up the game";
        }
    }

    return (
        <Stack bgcolor={bgColor} direction="row" gap={1} px={3} py={0.5}>
            {Icon && (
                <Icon
                    sx={{ color: theme.palette.grey[900] }}
                    fontSize="small"
                />
            )}
            <Typography color={theme.palette.grey[900]} variant="body2">
                Status: {text}
            </Typography>
        </Stack>
    );
}

export default RoundDetails;
