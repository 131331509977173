import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import { APIError, Execute } from "@reservoir0x/reservoir-sdk";
import { useEffect, useState } from "react";
import { BaseError } from "viem";

import useReservoirClient from "src/hooks/useReservoirClient";
import useTypeWriter from "src/hooks/useTypewriter";
import useWalletClient from "src/hooks/useWalletClient";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";
import { getTokenById } from "src/slices/tokenSlice";

import StyledModal from "src/components/StyledModal";

import { useAppDispatch } from "src/store";
import {
    StepData,
    getMessageFromPostError,
    onProgressAction,
} from "src/utils/reservoir";

enum ActionStep {
    Cancel = "Cancel",
    Approving = "Approving",
    Complete = "Complete",
}

export default function CancelOrderButton({
    tokenId,
    completeText,
    modalTitle,
    orderId,
    label = "Delete",
    onCloseAction,
}: {
    tokenId: number;
    modalTitle: string;
    completeText: string;
    orderId: string;
    label?: string;
    onCloseAction?: () => void;
}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [stepData, setStepData] = useState<StepData | null>(null);
    const [actionStep, setActionStep] = useState<ActionStep>(ActionStep.Cancel);

    const [typewriterText, setTypewriterText] = useState("");
    const { currentText: currentTypewriterText } = useTypeWriter({
        text: typewriterText,
        delay: 100,
        infinite: false,
    });

    const dispatch = useAppDispatch();
    const walletClient = useWalletClient();
    const reservoirClient = useReservoirClient();

    useEffect(() => {
        if (actionStep === ActionStep.Approving && stepData?.currentStepItem) {
            const newText =
                stepData.currentStepItem.txHashes &&
                stepData.currentStepItem.txHashes.length > 0
                    ? "Waiting for transaction to be validated"
                    : "Waiting for approval...";
            setTypewriterText((prev) => (prev !== newText ? newText : prev));
        }
    }, [actionStep, stepData?.currentStepItem]);

    const onCloseModal = () => {
        setModalIsOpen(false);
        setStepData(null);
        setActionStep(ActionStep.Cancel);
        setStepData(null);
        // Dispatch to have data updated
        if (onCloseAction) {
            onCloseAction();
        } else {
            // At least refresh token data
            dispatch(
                getTokenById({
                    reservoirClient,
                    tokenId: Number(tokenId),
                }),
            );
        }
    };

    const cancelOrder = async () => {
        try {
            setIsLoading(true);
            setActionStep(ActionStep.Approving);
            setModalIsOpen(true);

            await reservoirClient?.actions.cancelOrder({
                ids: [orderId],
                wallet: walletClient,
                onProgress: (steps: Execute["steps"]) =>
                    onProgressAction({
                        steps,
                        setStepData,
                        onComplete: () => setActionStep(ActionStep.Complete),
                    }),
            });
        } catch (_error: unknown) {
            const error = _error as APIError | BaseError;
            const message = getMessageFromPostError(error);
            console.error("Error canceling order", message, error);
            if (message) {
                dispatch(
                    setSnackbarFeedback({
                        type: SeverityType.ERROR,
                        message,
                    }),
                );
            }
            onCloseModal();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button
                variant="contained"
                color="error"
                onClick={cancelOrder}
                disabled={isLoading}
            >
                {label}
            </Button>
            {modalIsOpen && (
                <StyledModal
                    open={modalIsOpen}
                    onClose={() =>
                        actionStep !== ActionStep.Approving
                            ? onCloseModal()
                            : undefined
                    }
                    aria-labelledby="cancel-offer-dialog-title"
                    aria-describedby="cancel-offer-dialog-description"
                >
                    <Stack>
                        <DialogTitle id="cancel-offer-dialog-title">
                            {modalTitle}
                        </DialogTitle>
                        <DialogContent>
                            {actionStep === ActionStep.Approving ? (
                                <Typography>{currentTypewriterText}</Typography>
                            ) : null}
                            {actionStep === ActionStep.Complete ? (
                                <Typography>{completeText}</Typography>
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            {actionStep === ActionStep.Complete ? (
                                <Button
                                    onClick={onCloseModal}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            ) : null}
                        </DialogActions>
                    </Stack>
                </StyledModal>
            )}
        </>
    );
}
