import { Box, Button, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { ContractType } from "src/hooks/useContract";
import usePublicClient from "src/hooks/usePublicClient";
import {
    getRandomTokenPrice,
    selectIsContractStateOpen,
} from "src/slices/contractSettingsSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";
import { getCurrentUserWords } from "src/slices/wordSlice";

import CurrencyDisplay from "src/components/CurrencyDisplay";

import { CURRENCY_SYMBOL } from "src/constants";
import { ContractContext } from "src/contexts/ContractsContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";

export default function SellToContractInfoAndButton({
    tokenId,
    setDisableCloseModal,
    onCloseSellModal,
}: {
    tokenId: number;
    setDisableCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
    onCloseSellModal?: () => void;
}) {
    const { contract } = useContext(ContractContext);
    const { selectedAccount } = useContext(WalletContext);
    const publicClient = usePublicClient();
    const dispatch = useAppDispatch();

    const isContractStateOpen = useAppSelector(selectIsContractStateOpen);

    const [isLoading, setIsLoading] = useState(false);
    const [tokenSellPrice, setTokenSellPrice] = useState<bigint | null>(null);
    const [tokenPricePaid, setTokenPricePaid] = useState<bigint | null>(null);

    const fetchTokenSellInformation = async (
        tokenId: number,
        contract: ContractType,
    ) => {
        const [tokenSellPrice, tokenPricePaid] =
            await contract.read.getSellInformation([BigInt(tokenId)]);

        setTokenSellPrice(tokenSellPrice);
        setTokenPricePaid(tokenPricePaid);
    };

    useEffect(() => {
        if (
            contract?.address &&
            tokenPricePaid === null &&
            tokenSellPrice === null
        ) {
            fetchTokenSellInformation(tokenId, contract);
        }
    }, [contract, tokenId, tokenPricePaid, tokenSellPrice]);

    const diffPrices = (tokenSellPrice ?? 0n) - (tokenPricePaid ?? 0n);
    const willGain = diffPrices > 0;
    const willLoose = diffPrices < 0;
    let outcomeValue = 0n;
    if (willGain || willLoose) {
        outcomeValue = willGain ? diffPrices : -diffPrices;
    }

    const handleSell = async () => {
        try {
            if (!contract) {
                throw new Error("No contract available to sell");
            }
            if (!tokenId) {
                throw new Error("No token ID to sell");
            }
            if (!selectedAccount) {
                throw new Error("No account available to sell");
            }

            setIsLoading(true);
            setDisableCloseModal(true);
            const tx = await contract.write.sellToken([BigInt(tokenId)]);
            const receipt = await publicClient.waitForTransactionReceipt({
                hash: tx,
            });
            console.log("Transaction receipt:", receipt);

            if (receipt.status === "success") {
                dispatch(
                    setSnackbarFeedback({
                        type: SeverityType.SUCCESS,
                        message: "Word sold successfully!",
                    }),
                );
            } else {
                dispatch(
                    setSnackbarFeedback({
                        type: SeverityType.ERROR,
                        message: "Transaction failed",
                    }),
                );
            }
            await dispatch(
                getCurrentUserWords({ contract, address: selectedAccount }),
            ).unwrap();
            dispatch(getRandomTokenPrice({ contract }));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setDisableCloseModal(false);
            if (onCloseSellModal) {
                onCloseSellModal();
            }
        }
    };

    return (
        <Stack gap={1}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
            >
                <strong>Purchase Price:</strong>
                <span>
                    <CurrencyDisplay
                        amount={BigInt(tokenPricePaid ?? 0n)}
                        currencySymbol={CURRENCY_SYMBOL.USD}
                        toFixed={2}
                        sx={{ fontWeight: "bold" }}
                        component="span"
                    />
                    &nbsp;(
                    <CurrencyDisplay
                        amount={BigInt(tokenPricePaid ?? 0n)}
                        currencySymbol={CURRENCY_SYMBOL.ETH}
                        component="span"
                        toFixed={6}
                    />
                    )
                </span>
            </Box>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mb={2}
            >
                <strong>Current Sell Price:</strong>
                <span>
                    <CurrencyDisplay
                        amount={BigInt(tokenSellPrice ?? 0n)}
                        currencySymbol={CURRENCY_SYMBOL.USD}
                        toFixed={2}
                        sx={{ fontWeight: "bold" }}
                        component="span"
                    />
                    &nbsp;(
                    <CurrencyDisplay
                        amount={BigInt(tokenSellPrice ?? 0n)}
                        currencySymbol={CURRENCY_SYMBOL.ETH}
                        component="span"
                        toFixed={6}
                    />
                    )
                </span>
            </Box>
            <Button
                onClick={handleSell}
                autoFocus
                color="primary"
                variant="contained"
                disabled={isLoading || !contract || !isContractStateOpen}
                fullWidth
            >
                Sell it now for a&nbsp;
                <CurrencyDisplay
                    amount={outcomeValue}
                    currencySymbol={CURRENCY_SYMBOL.USD}
                    toFixed={2}
                    sx={{ fontWeight: "bold" }}
                    component="span"
                />
                &nbsp;{willGain ? "profit" : "loss"}
            </Button>
        </Stack>
    );
}
