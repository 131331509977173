import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xxs: true;
    }
}

const theme = createTheme({
    typography: {
        fontFamily: ["Comic Sans MS", "Comic Sans", "cursive"].join(","),
    },
    palette: {
        mode: "dark",
        primary: {
            main: "#45F0DF",
        },
        secondary: {
            main: "#515050",
        },
    },
    breakpoints: {
        values: {
            xxs: 0,
            xs: 500,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiTooltip: {
            defaultProps: {
                enterTouchDelay: 10,
                leaveTouchDelay: 10000,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 15,
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                grouped: {
                    "&:last-of-type": {
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15,
                    },
                    "&:first-of-type": {
                        borderTopLeftRadius: 15,
                        borderBottomLeftRadius: 15,
                    },
                },
            },
        },
    },
});

export default theme;
