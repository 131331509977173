import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { ReservoirClient } from "@reservoir0x/reservoir-sdk";
import { AxiosError } from "axios";
import { Address, ReadContractErrorType } from "viem";

import { ContractType } from "src/hooks/useContract";
import {
    GetWordsParams,
    getWordFromText as getWordFromTextFromBackend,
    getWords as getWordsFromBackend,
} from "src/services/api";
import { getTokensByIds } from "src/slices/tokenSlice";
import { getChartsDataByWordIds } from "src/slices/wordChartDataSlice";

import { RootState } from "src/store";

export type Token = {
    tokenId: number;
    ownerAddress: Address;
};
export interface BackendWord {
    id: number;
    currentFrequency: number;
    isTopWord: boolean;
    latestUpdateFrequency: number;
    price: number;
    text: string;
    variationOneHour: number | null;
    variationTwentyFourHours: number | null;
    variationSevenDays: number | null;
    tokens: Token[];
}

interface ContractWord {
    frequency: number;
    word: string;
    tokenId: number;
}

interface IWordState {
    currentUserWordsCount: number;
    currentUserWords: Omit<ContractWord, "frequency">[];
    wordsIdsPerPage: Record<number, number[]>;
    totalWords: number;
    totalWordsInGame: number;
    wordsById: Record<number, BackendWord>;
    statuses: Record<string, "idle" | "loading" | "succeeded" | "failed">;
    error: string | null;
}

const initialState: IWordState = {
    currentUserWordsCount: 0,
    currentUserWords: [],
    wordsIdsPerPage: {},
    totalWords: 0, // total words in the query, so with filters if we use them
    totalWordsInGame: 0,
    wordsById: {},
    statuses: {
        getWords: "idle",
        getCurrentUserWords: "idle",
        getWordFromText: "idle",
    },
    error: null,
};

// Thunk for fetching words
export const getWords = createAsyncThunk<
    {
        words: BackendWord[];
        totalWords: number;
        page: number;
        rowsPerPage: number;
        totalWordsInGame: number;
    },
    GetWordsParams & { reservoirClient?: ReservoirClient | null }
>("word/getWords", async ({ reservoirClient, ...rest }, thunkAPI) => {
    try {
        const words = await getWordsFromBackend(rest);
        const wordIds = words.items.map(({ id }) => id);
        if (wordIds.length) {
            thunkAPI.dispatch(
                getChartsDataByWordIds({
                    wordIds,
                }),
            );
        }
        const tokenIds =
            words.items
                ?.flatMap((word) => word?.tokens || [])
                .map((token) => token.tokenId)
                .sort() ?? [];
        if (tokenIds?.length && reservoirClient) {
            thunkAPI.dispatch(
                getTokensByIds({
                    tokenIds,
                    reservoirClient,
                }),
            );
        }
        return {
            words: words.items,
            totalWords: words.total,
            page: words.page || 0,
            rowsPerPage: words.rowsPerPage || 0,
            totalWordsInGame: words.totalWordsInGame || 0,
        };
    } catch (_error: unknown) {
        const error = _error as AxiosError | ReadContractErrorType;
        console.warn("Error while fetching words: ", error);
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getCurrentUserWords = createAsyncThunk<
    {
        words: ContractWord[];
        totalWords: number;
    },
    {
        contract: ContractType;
        address: Address;
    },
    { rejectValue: string }
>("word/getCurrentUserWords", async ({ address, contract }, thunkAPI) => {
    try {
        const totalWords = await contract.read.balanceOf([address], {
            account: address,
        });
        const words = (await contract.read.getUserTokenData([
            address,
        ])) as unknown as ContractWord[];

        return {
            words: words.map((word) => ({
                ...word,
                frequency: Number(word.frequency),
                tokenId: Number(word.tokenId),
            })),
            totalWords: Number(totalWords),
        };
    } catch (_error) {
        const error = _error as AxiosError | ReadContractErrorType;
        console.warn("Error while fetching words: ", error);
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const getWordFromText = createAsyncThunk<
    BackendWord,
    { wordText: string },
    { rejectValue: string }
>("word/getWordFromText", async ({ wordText }, thunkAPI) => {
    try {
        const word = await getWordFromTextFromBackend(wordText);

        return word;
    } catch (_error: unknown) {
        const error = _error as AxiosError | ReadContractErrorType;
        console.warn("Error while fetching words: ", error);
        return thunkAPI.rejectWithValue(error.message);
    }
});

export const wordSlice = createSlice({
    name: "word",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWords.fulfilled, (state, { payload }) => {
                state.statuses.getWords = "succeeded";
                const { words, totalWords, totalWordsInGame, page } = payload;
                const wordsById = { ...state.wordsById };
                state.wordsIdsPerPage[page] = [];
                words.forEach((word) => {
                    if (!state.wordsById[word.id]) {
                        state.wordsById[word.id] = {} as BackendWord;
                    }
                    wordsById[word.id] = {
                        ...state.wordsById[word.id],
                        ...word,
                    };
                    state.wordsIdsPerPage[page].push(word.id);
                });
                state.wordsById = wordsById;
                state.totalWords = totalWords;
                state.totalWordsInGame = totalWordsInGame;
            })
            .addCase(getWords.pending, (state) => {
                state.statuses.getWords = "loading";
                state.error = null;
            })
            .addCase(getWords.rejected, (state, { error }) => {
                if (error.message !== "Aborted") {
                    state.statuses.getWords = "failed";
                    state.error = error.message as string;
                }
            });
        builder
            .addCase(getCurrentUserWords.fulfilled, (state, { payload }) => {
                state.statuses.getCurrentUserWords = "succeeded";
                const { words, totalWords } = payload;
                state.currentUserWordsCount = totalWords;
                state.currentUserWords = words.map(({ word, tokenId }) => ({
                    word,
                    tokenId: Number(tokenId),
                }));
            })
            .addCase(getCurrentUserWords.pending, (state) => {
                state.statuses.getCurrentUserWords = "loading";
                state.error = null;
            })
            .addCase(getCurrentUserWords.rejected, (state, { payload }) => {
                state.statuses.getCurrentUserWords = "failed";
                if (payload) {
                    state.error = payload;
                }
            });
        builder
            .addCase(getWordFromText.fulfilled, (state, { payload }) => {
                state.statuses.getWordFromText = "succeeded";
                state.wordsById[payload.id] = {
                    ...state.wordsById[payload.id],
                    ...payload,
                };
            })
            .addCase(getWordFromText.pending, (state) => {
                state.statuses.getWordFromText = "loading";
                state.error = null;
            })
            .addCase(getWordFromText.rejected, (state, { payload }) => {
                state.statuses.getWordFromText = "failed";
                if (payload) {
                    state.error = payload;
                }
            });
    },
});

const selectWordState = (state: RootState) => state.word;
export const selectWordsByPage = (page: number) =>
    createSelector(
        [selectWordState],
        (wordState) =>
            wordState.wordsIdsPerPage[page]?.map(
                (id) => wordState.wordsById[id],
            ) || [],
    );
export const selectTotalWords = (state: RootState) => state.word.totalWords;
export const selectTotalWordsInGame = (state: RootState) =>
    state.word.totalWordsInGame;
export const selectWordStatuses = (state: RootState) => state.word.statuses;
export const selectCurrentUserWords = createSelector(selectWordState, (state) =>
    state.currentUserWords.map(({ word }) => word),
);
export const selectCurrentUserWordsCount = createSelector(
    selectWordState,
    (word) => word.currentUserWordsCount,
);

export default wordSlice.reducer;
