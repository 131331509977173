import { APIError, Execute } from "@reservoir0x/reservoir-sdk";
import { AxiosError } from "axios";
import { BaseError } from "viem";

export const getMessageFromPostError = (
    error: APIError | BaseError | AxiosError,
) => {
    let message;
    if (error instanceof APIError) {
        message = error.rawError.message;
        if (error.rawError?.errors?.length) {
            message += ". ";
            message += error.rawError.errors
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((err: any) => err?.message)
                .join(" ");
        }
    }
    if (error instanceof BaseError) {
        message = error.shortMessage;
        if (error.details) {
            message += ` ${error.details}`;
        }
    }
    if (error instanceof AxiosError || !message) {
        message = error.message;
    }
    return message;
};

export type StepData = {
    totalSteps: number;
    stepProgress: number;
    currentStep: Execute["steps"][0];
    currentStepItem: NonNullable<Execute["steps"][0]["items"]>[0];
};
type OnProgressActionParams = {
    steps: Execute["steps"];
    setStepData: React.Dispatch<React.SetStateAction<StepData | null>>;
    onComplete: () => void;
};
export const onProgressAction = ({
    steps,
    setStepData,
    onComplete,
}: OnProgressActionParams) => {
    if (!steps) {
        return;
    }
    const executableSteps = steps.filter(
        (step) => step.items && step.items.length > 0,
    );

    const stepCount = executableSteps.length;

    let currentStepItem:
        | NonNullable<Execute["steps"][0]["items"]>[0]
        | undefined;

    const currentStepIndex = executableSteps.findIndex((step) => {
        currentStepItem = step.items?.find(
            (item) => item.status === "incomplete",
        );
        return currentStepItem;
    });

    const currentStep =
        currentStepIndex > -1
            ? executableSteps[currentStepIndex]
            : executableSteps[stepCount - 1];

    if (currentStepItem) {
        setStepData({
            totalSteps: stepCount,
            stepProgress: currentStepIndex,
            currentStep,
            currentStepItem,
        });
    } else if (
        steps.every(
            (step) =>
                !step.items ||
                step.items.length == 0 ||
                step.items?.every((item) => item.status === "complete"),
        )
    ) {
        onComplete();
    }
};
