import {
    PropsWithChildren,
    createContext,
    useCallback,
    useEffect,
    useState,
} from "react";
import { Address } from "viem";

import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";

import { getEnvDependentChain } from "src/chains";
import { useAppDispatch } from "src/store";

type SelectedAccountByWallet = Record<string, Address | null>;

interface WalletContext {
    wallets: Record<string, EIP6963ProviderDetail>; // Record of wallets by UUID
    selectedWallet: EIP6963ProviderDetail | null; // Currently selected wallet
    selectedAccount: Address | null; // Account address of selected wallet
    connectWallet: (walletUuid: string) => Promise<void>; // Function to trigger wallet connection
    disconnectWallet: () => void; // Function to trigger wallet disconnection
    handleSwitchNetwork: () => Promise<void>; // Function to trigger network switch
    isCorrectNetwork: boolean; // Whether the current network is correct
}

declare global {
    interface WindowEventMap {
        "eip6963:announceProvider": CustomEvent;
    }
}

export const WalletContext = createContext<WalletContext>(
    null as unknown as WalletContext,
);

export const WalletProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    const [wallets, setWallets] = useState<
        Record<string, EIP6963ProviderDetail>
    >({});
    const [selectedWalletRdns, setSelectedWalletRdns] =
        useState<Address | null>(null);
    const [selectedAccountByWalletRdns, setSelectedAccountByWalletRdns] =
        useState<SelectedAccountByWallet>({});
    const [isCorrectNetwork, setIsCorrectNetwork] = useState(true);

    useEffect(() => {
        const savedSelectedWalletRdns =
            localStorage.getItem("selectedWalletRdns");
        const savedSelectedAccountByWalletRdns = localStorage.getItem(
            "selectedAccountByWalletRdns",
        );

        if (savedSelectedAccountByWalletRdns) {
            setSelectedAccountByWalletRdns(
                JSON.parse(savedSelectedAccountByWalletRdns),
            );
        }

        function onAnnouncement(event: EIP6963AnnounceProviderEvent) {
            setWallets((currentWallets) => ({
                ...currentWallets,
                [event.detail.info.rdns]: event.detail,
            }));

            if (
                savedSelectedWalletRdns &&
                event.detail.info.rdns === savedSelectedWalletRdns
            ) {
                setSelectedWalletRdns(savedSelectedWalletRdns as Address);
            }
        }

        window.addEventListener("eip6963:announceProvider", onAnnouncement);
        window.dispatchEvent(new Event("eip6963:requestProvider"));

        return () =>
            window.removeEventListener(
                "eip6963:announceProvider",
                onAnnouncement,
            );
    }, []);

    const validateNetwork = useCallback(
        async (chainId?: string) => {
            if (selectedWalletRdns) {
                const wallet = wallets[selectedWalletRdns];
                if (!chainId) {
                    chainId = (await wallet.provider.request({
                        method: "eth_chainId",
                    })) as string;
                }
                const correctChainId = getEnvDependentChain()?.id;
                setIsCorrectNetwork(
                    chainId === `0x${correctChainId.toString(16)}`,
                );
            }
        },
        [selectedWalletRdns, wallets],
    );

    useEffect(() => {
        validateNetwork();
    }, [validateNetwork]);

    const handleSwitchNetwork = useCallback(async () => {
        if (selectedWalletRdns) {
            const wallet = wallets[selectedWalletRdns];
            const envChain = getEnvDependentChain();

            try {
                await wallet.provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                        {
                            chainId: `0x${envChain?.id.toString(16)}`,
                        },
                    ],
                });
                validateNetwork();
            } catch (_switchError) {
                const switchError = _switchError as WalletError;
                console.error("Failed to switch network:", switchError);
                // This error code indicates that the chain has not been added to MetaMask.
                if (switchError.code === 4902 || switchError.code === -32603) {
                    try {
                        let rpcUrls: string[] = [];
                        let blockExplorerUrls: string[] = [];
                        if (envChain) {
                            rpcUrls = Object.values(envChain.rpcUrls).map(
                                ({ http }) => http[0],
                            );
                            if (envChain.blockExplorers) {
                                blockExplorerUrls = Object.values(
                                    envChain.blockExplorers,
                                ).map(({ url }) => url);
                            }
                        }

                        await wallet.provider.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: `0x${envChain?.id.toString(16)}`,
                                    rpcUrls,
                                    blockExplorerUrls,
                                    chainName: envChain?.name,
                                    nativeCurrency: envChain?.nativeCurrency,
                                },
                            ],
                        });
                    } catch (addError) {
                        console.error("Failed to add network:", addError);
                    }
                }
            }
        }
    }, [selectedWalletRdns, wallets, validateNetwork]);

    const connectWallet = useCallback(
        async (walletRdns: string) => {
            try {
                const wallet = wallets[walletRdns];
                const accounts = (await wallet.provider.request({
                    method: "eth_requestAccounts",
                })) as Address[];

                if (accounts?.[0]) {
                    setSelectedWalletRdns(wallet.info.rdns as Address);
                    setSelectedAccountByWalletRdns((currentAccounts) => ({
                        ...currentAccounts,
                        [wallet.info.rdns]: accounts[0],
                    }));
                    localStorage.setItem(
                        "selectedWalletRdns",
                        wallet.info.rdns,
                    );
                    localStorage.setItem(
                        "selectedAccountByWalletRdns",
                        JSON.stringify({
                            ...selectedAccountByWalletRdns,
                            [wallet.info.rdns]: accounts[0],
                        }),
                    );
                }
            } catch (error) {
                console.error("Failed to connect to provider:", error);
                const walletError: WalletError = error as WalletError;

                dispatch(
                    setSnackbarFeedback({
                        type: SeverityType.ERROR,
                        message: `Code: ${walletError.code} \nError Message: ${walletError.message}`,
                    }),
                );
            }
        },
        [wallets, selectedAccountByWalletRdns, dispatch],
    );

    const disconnectWallet = useCallback(
        async (fromUser = false) => {
            if (selectedWalletRdns) {
                setSelectedAccountByWalletRdns((currentAccounts) => ({
                    ...currentAccounts,
                    [selectedWalletRdns]: null,
                }));

                const wallet = wallets[selectedWalletRdns];
                setSelectedWalletRdns(null);
                localStorage.removeItem("selectedWalletRdns");
                if (!fromUser) {
                    try {
                        await wallet.provider.request({
                            method: "wallet_revokePermissions",
                            params: [{ eth_accounts: {} }],
                        });
                    } catch (error) {
                        console.error("Failed to revoke permissions:", error);
                    }
                }
            }
        },
        [selectedWalletRdns, wallets],
    );

    const handleAccountsChanged = useCallback(
        (accounts: Address[], selectedWalletRdns: Address) => {
            if (accounts.length === 0) {
                disconnectWallet(true);
            } else if (
                accounts[0] !== selectedAccountByWalletRdns[selectedWalletRdns]
            ) {
                setSelectedAccountByWalletRdns((currentAccounts) => ({
                    ...currentAccounts,
                    [selectedWalletRdns]: accounts[0],
                }));
                localStorage.setItem(
                    "selectedAccountByWalletRdns",
                    JSON.stringify({
                        ...selectedAccountByWalletRdns,
                        [selectedWalletRdns]: accounts[0],
                    }),
                );
            }
        },
        [disconnectWallet, selectedAccountByWalletRdns],
    );

    useEffect(() => {
        if (selectedWalletRdns) {
            const wallet = wallets[selectedWalletRdns];
            wallet.provider.on("accountsChanged", (accounts) => {
                handleAccountsChanged(accounts, selectedWalletRdns);
            });
            wallet.provider.on("chainChanged", (chainId) => {
                validateNetwork(chainId);
            });
        }
    }, [
        disconnectWallet,
        handleAccountsChanged,
        selectedAccountByWalletRdns,
        selectedWalletRdns,
        validateNetwork,
        wallets,
    ]);

    const contextValue: WalletContext = {
        wallets,
        selectedWallet:
            selectedWalletRdns === null ? null : wallets[selectedWalletRdns],
        selectedAccount:
            selectedWalletRdns === null
                ? null
                : selectedAccountByWalletRdns[selectedWalletRdns],
        connectWallet,
        disconnectWallet,
        handleSwitchNetwork,
        isCorrectNetwork,
    };

    return (
        <WalletContext.Provider value={contextValue}>
            {children}
        </WalletContext.Provider>
    );
};
