import { useMemo } from "react";
import {
    Chain,
    HttpTransport,
    PublicClient,
    createPublicClient,
    http,
} from "viem";
import { mainnet } from "viem/chains";

import { getChainByStringID, getEnvDependentChain } from "src/chains";

const publicClients: Record<string, PublicClient<HttpTransport, Chain>> = {};

const usePublicClient = (chainId?: string) => {
    const chain = useMemo(() => {
        let memoizedChain;
        if (!chainId) {
            memoizedChain = getEnvDependentChain();
        } else {
            memoizedChain = getChainByStringID(chainId && `${chainId}`);
        }

        if (!memoizedChain) {
            memoizedChain = mainnet;
        }

        return memoizedChain;
    }, [chainId]);

    const instanceKey = chain.id;
    if (!publicClients[instanceKey]) {
        publicClients[instanceKey] = createPublicClient({
            chain,
            transport: http(),
        });
    }

    return publicClients[instanceKey];
};

export default usePublicClient;
