import {
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ObjectToCamel } from "ts-case-convert/lib/caseConvert";
import { ReadContractErrorType } from "viem";

import {
    getChartsData as getChartsDataFromBackend,
    getWordChartData as getWordChartDataFromBackend,
} from "src/services/api";

import { RootState } from "src/store";

export type ChartData = ObjectToCamel<{
    weighted_relative_frequency: number;
    date: string;
}>;
interface IWordChartDataState {
    chartDataByWordId: Record<number, ChartData[]>;
    statusesByWordId: Record<
        number,
        "idle" | "loading" | "succeeded" | "failed"
    >; // by wordId
    errorByWordId: Record<number, string | null>; // by wordId
}

const initialState: IWordChartDataState = {
    chartDataByWordId: {},
    statusesByWordId: {},
    errorByWordId: {},
};

// Thunk for fetching word chart data
export const getWordChartData = createAsyncThunk<
    {
        wordId: number;
        chartData: ChartData[];
    },
    { wordId: number }
>(
    "wordChartData/getWordChartData",
    async (params: { wordId: number }, thunkAPI) => {
        const { wordId } = params;

        try {
            const chartData = await getWordChartDataFromBackend({ wordId });
            return {
                wordId,
                chartData: chartData.data,
            };
        } catch (_error: unknown) {
            const error = _error as AxiosError | ReadContractErrorType;
            console.warn("Error while fetching words: ", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

// Thunk for fetching multiple chart data by word ids
export const getChartsDataByWordIds = createAsyncThunk<
    Array<{
        wordId: number;
        frequencies: ChartData[];
    }>,
    { wordIds: number[] }
>(
    "wordChartData/getChartsDataByWordIds",
    async (params: { wordIds: number[] }, thunkAPI) => {
        const { wordIds } = params;
        try {
            const data = await getChartsDataFromBackend({ wordIds });
            return data.data;
        } catch (_error: unknown) {
            const error = _error as AxiosError | ReadContractErrorType;
            console.warn("Error while fetching words: ", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

export const wordChartDataSlice = createSlice({
    name: "wordChartData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWordChartData.fulfilled, (state, { payload }) => {
                const { wordId, chartData } = payload;
                state.statusesByWordId[wordId] = "succeeded";
                state.errorByWordId[wordId] = null;
                state.chartDataByWordId[wordId] = chartData;
            })
            .addCase(getWordChartData.pending, (state, { meta }) => {
                const wordId = meta.arg.wordId;
                state.statusesByWordId[wordId] = "loading";
                state.errorByWordId[wordId] = null;
            })
            .addCase(getWordChartData.rejected, (state, { payload, meta }) => {
                const wordId = meta.arg.wordId;
                state.statusesByWordId[wordId] = "failed";
                state.errorByWordId[wordId] = payload as string;
            })
            .addCase(getChartsDataByWordIds.fulfilled, (state, { payload }) => {
                payload.forEach((word) => {
                    state.statusesByWordId[word.wordId] = "succeeded";
                    state.errorByWordId[word.wordId] = null;
                    state.chartDataByWordId[word.wordId] = word.frequencies;
                });
            })
            .addCase(getChartsDataByWordIds.pending, (state, { meta }) => {
                const wordIds = meta.arg.wordIds;
                wordIds.forEach((wordId) => {
                    state.statusesByWordId[wordId] = "loading";
                    state.errorByWordId[wordId] = null;
                });
            })
            .addCase(
                getChartsDataByWordIds.rejected,
                (state, { payload, meta }) => {
                    const wordIds = meta.arg.wordIds;
                    wordIds.forEach((wordId) => {
                        state.statusesByWordId[wordId] = "failed";
                        state.errorByWordId[wordId] = payload as string;
                    });
                },
            );
    },
});

const selectWordChartDataState = (state: RootState) => state.wordChartData;

export const selectWordChartData = (wordId: number) =>
    createSelector(selectWordChartDataState, (state) => {
        return state.chartDataByWordId[wordId];
    });

export const selectWordChartDataTotal = createSelector(
    selectWordChartDataState,
    (state) => {
        return Object.values(state.chartDataByWordId).filter(Boolean).length;
    },
);

export const selectWordChartDataStatus = (wordId: number) =>
    createSelector(selectWordChartDataState, (state) => {
        return state.statusesByWordId[wordId];
    });

export const selectWordChartDataError = (wordId: number) =>
    createSelector(selectWordChartDataState, (state) => {
        return state.errorByWordId[wordId];
    });

export default wordChartDataSlice.reducer;
