import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    tableCellClasses,
    useTheme,
} from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { Address as AddressType } from "viem";

import { TokenBids } from "src/services/reservoir";

import Address from "src/components/Address";
import AcceptOfferButton from "src/components/TradeModal/AcceptOfferButton";

export default function OffersTable({
    offers,
    shouldIncludeAction = false,
}: {
    offers: TokenBids["orders"];
    shouldIncludeAction?: boolean;
}) {
    const theme = useTheme();
    const headerCellSX = {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
    };
    return (
        <Table
            stickyHeader
            sx={{
                [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                    padding: "0.25rem",
                },
            }}
        >
            <TableHead>
                <TableRow>
                    <TableCell sx={headerCellSX}>Price</TableCell>
                    <TableCell sx={headerCellSX}>From</TableCell>
                    <TableCell sx={headerCellSX}>Expiration</TableCell>
                    {shouldIncludeAction && <TableCell />}
                </TableRow>
            </TableHead>
            <TableBody>
                {offers?.map((offer) => (
                    <TableRow key={offer.id}>
                        <TableCell
                            sx={{ fontWeight: "bold" }}
                        >{`$${offer.price?.amount?.usd}`}</TableCell>
                        <TableCell>
                            <Address account={offer.maker as AddressType} />
                        </TableCell>
                        <TableCell>
                            {formatDistanceToNowStrict(
                                new Date(offer.expiration * 1_000),
                                { addSuffix: true },
                            )}
                        </TableCell>
                        {shouldIncludeAction && (
                            <TableCell>
                                <AcceptOfferButton offer={offer} />
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
