export function formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
}

export const formatMillisecondsToTime = ({
    milliseconds,
    shouldIncludeSeconds = true,
    prefix = "",
}: {
    milliseconds: number;
    shouldIncludeSeconds?: boolean;
    prefix?: string;
}) => {
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);

    let result = prefix;
    if (days > 0) result += ` ${days}d`;
    if (hours > 0) result += ` ${hours}h`;
    if (minutes > 0) result += ` ${minutes}m`;
    if (seconds >= 0 && shouldIncludeSeconds) result += ` ${seconds}s`;

    return result.trim();
};

export const formatRemainingTime = ({
    dateString,
    prefix = "",
    shouldIncludeSeconds = true,
}: {
    dateString: string;
    shouldIncludeSeconds?: boolean;
    prefix?: string;
}) => {
    const date = new Date(dateString).getTime();
    const now = new Date().getTime();

    const diff = date - now;

    if (diff <= 0) return "soon";

    return formatMillisecondsToTime({
        milliseconds: diff,
        shouldIncludeSeconds,
        prefix,
    });
};
