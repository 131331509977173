import CloseIcon from "@mui/icons-material/Close";
import { IconButton, ModalProps, Stack, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import useReservoirClient from "src/hooks/useReservoirClient";
import {
    getTokenById,
    selectCurrentUserBidByTokenId,
    selectTokenById,
} from "src/slices/tokenSlice";

import StyledModal from "src/components/StyledModal";
import TradeModalBuyContent from "src/components/TradeModal/TradeModalBuyContent";
import TradeModalMyListingContent from "src/components/TradeModal/TradeModalMyListingContent";
import TradeModalMyOfferContent from "src/components/TradeModal/TradeModalMyOfferContent";
import TradeModalOffersContent from "src/components/TradeModal/TradeModalOffersContent";
import TradeModalSellContent from "src/components/TradeModal/TradeModalSellContent";

import { MARKET_PLACE_CONTRACT_ADDRESS } from "src/constants";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";

type TradeModalProps = Omit<ModalProps, "children" | "onClose"> & {
    tokenId: number;
    onClose: () => void;
};

const TradeModal = ({ tokenId, onClose, open }: TradeModalProps) => {
    const dispatch = useAppDispatch();
    const reservoirClient = useReservoirClient();

    const [disabledCloseModal, setDisableCloseModal] = useState(false);

    const token = useAppSelector(selectTokenById(tokenId));
    const currentUserBid = useAppSelector(
        selectCurrentUserBidByTokenId(Number(tokenId)),
    );
    const { selectedAccount } = useContext(WalletContext);
    const tokenOwer = token?.token?.owner?.toLowerCase();
    const tokenIsOwnedByCurrentUser =
        tokenOwer === selectedAccount?.toLowerCase();
    const tokenIsOwnedByOtherUser = ![
        MARKET_PLACE_CONTRACT_ADDRESS.toLowerCase(),
        selectedAccount?.toLowerCase(),
    ].includes(tokenOwer);
    const tokenIsListed = Boolean(token?.market?.floorAsk?.id);

    const [activeTab, setActiveTab] = useState<
        "sell" | "offers" | "buy" | "my-listing" | "my-offer"
    >(tokenIsOwnedByCurrentUser ? "sell" : "buy");

    useEffect(() => {
        dispatch(
            getTokenById({
                reservoirClient,
                tokenId: Number(tokenId),
            }),
        );
    }, [dispatch, reservoirClient, tokenId]);

    if (!token) {
        return null;
    }

    return (
        <StyledModal
            open={open}
            onClose={disabledCloseModal ? undefined : onClose}
        >
            <Stack padding={1} height="95%">
                <IconButton
                    onClick={onClose}
                    disabled={disabledCloseModal}
                    sx={{
                        position: "absolute",
                        top: 10,
                        right: 10,
                        zIndex: 100,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Tabs
                    value={activeTab}
                    onChange={(_, value) => {
                        setActiveTab(value);
                    }}
                >
                    {tokenIsOwnedByCurrentUser && (
                        <Tab value="sell" label="Sell" />
                    )}
                    {tokenIsOwnedByOtherUser && <Tab value="buy" label="Buy" />}
                    <Tab
                        value="offers"
                        label={
                            tokenIsOwnedByCurrentUser
                                ? "Accept offer"
                                : "Offers"
                        }
                    />
                    {tokenIsOwnedByCurrentUser && tokenIsListed && (
                        <Tab value="my-listing" label="My listing" />
                    )}
                    {currentUserBid && (
                        <Tab value="my-offer" label="My offer" />
                    )}
                </Tabs>
                {activeTab === "sell" && (
                    <TradeModalSellContent
                        token={token}
                        setDisableCloseModal={setDisableCloseModal}
                    />
                )}
                {activeTab === "buy" && <TradeModalBuyContent token={token} />}
                {activeTab === "offers" && (
                    <TradeModalOffersContent token={token} />
                )}
                {activeTab === "my-listing" && (
                    <TradeModalMyListingContent token={token} />
                )}
                {activeTab === "my-offer" && (
                    <TradeModalMyOfferContent token={token} />
                )}
            </Stack>
        </StyledModal>
    );
};

export default TradeModal;
