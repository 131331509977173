import { Button, Skeleton } from "@mui/material";
import { useState } from "react";

import { selectTokenById, selectTokenStatuses } from "src/slices/tokenSlice";
import { BackendWord } from "src/slices/wordSlice";

import TradeModal from "src/components/TradeModal/TradeModal";

import { MARKET_PLACE_CONTRACT_ADDRESS } from "src/constants";
import { useAppSelector } from "src/store";

export default function ActionsCell({ word }: { word: BackendWord }) {
    const statuses = useAppSelector(selectTokenStatuses);
    const tokenId = word.tokens[0]?.tokenId; // For now we have (and we handle) only one token per word
    const [tradeModalIsOpen, setTradeModalIsOpen] = useState(false);
    const tokenData = useAppSelector(selectTokenById(tokenId));
    const tokenOwer = tokenData?.token?.owner?.toLowerCase();
    const tokenIsOwnedByContract =
        tokenOwer === MARKET_PLACE_CONTRACT_ADDRESS.toLowerCase();

    if (["idle", "loading"].includes(statuses.getTokensByIds)) {
        return (
            <Skeleton
                variant="rounded"
                width="100px"
                height="30px"
                sx={{ margin: "auto" }}
            />
        );
    }
    if (word.tokens.length === 0 || !tokenData || tokenIsOwnedByContract) {
        return "No one yet"; // word.tokens.length === 0 could happen the time tokens are inserted in the DB
    }

    return (
        <>
            <Button
                variant="contained"
                onClick={() => setTradeModalIsOpen(true)}
            >
                Trade
            </Button>
            {tradeModalIsOpen ? (
                <TradeModal
                    open={tradeModalIsOpen}
                    onClose={() => setTradeModalIsOpen(false)}
                    tokenId={tokenId}
                />
            ) : null}
        </>
    );
}
