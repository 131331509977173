import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    Box,
    Button,
    Container,
    Link,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import BuyWord from "src/components/BuyWord";
import StyledModal from "src/components/StyledModal";
import WordsTable from "src/components/WordsTable";
import WordsTreemapChart from "src/components/WordsTreemapChart";

import { PATHS } from "src/constants";

const CONTENT = [
    {
        title: "What is Words.fun?",
        content: "Spot the next narrative, buy the word, and profit.",
    },
    {
        title: "How can players succeed in this game?",
        content:
            "Predict the next narrative or buzz by snagging the hottest words in the crypto sphere before they explode and sell them for a profit!",
    },
    {
        title: "How do you measure the popularity of a word?",
        content: (
            <>
                We’re monitoring and analyzing the conversations between your
                favorite degens in Crypto Twitter to see how the mindshare
                evolves. You can find the current list of accounts tracked{" "}
                <Link
                    href="https://x.com/i/lists/1834624677248893302"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    here
                </Link>
                .
            </>
        ),
    },
    {
        title: "How new words are added?",
        content:
            "Each day, 12 new words are automatically added based on their mentions and activity on Crypto Twitter. The most talked-about words with growing momentum make it to the platform, giving you the opportunity to buy them before they trend further.",
    },
];

const Landing = () => {
    const navigate = useNavigate();
    const { hash } = useLocation();

    const [activeTab, setActiveTab] = useState<"top25" | "academic">(
        hash === "#my-words" ? "academic" : "top25",
    );
    const [readInstructions, setReadInstructions] = useState<boolean>(
        localStorage.getItem("readInstructions") === "true",
    );
    const [openInstructionsModal, setOpenInstructionsModal] =
        useState<boolean>(!readInstructions);

    const handleCloseInstructionsModal = () => {
        setOpenInstructionsModal(false);
        localStorage.setItem("readInstructions", "true");
        setReadInstructions(true);
    };

    useEffect(() => {
        if (hash === "#my-words" && activeTab === "top25") {
            setActiveTab("academic");
        }
    }, [activeTab, hash]);

    return (
        <Container
            maxWidth="xl"
            sx={{
                flex: 1,
                paddingBottom: 2,
            }}
        >
            <BuyWord />
            <Stack direction="row" justifyContent="center" mb={2}>
                <Link
                    sx={{
                        cursor: "pointer",
                        alignItems: "center",
                        display: "flex",
                    }}
                    onClick={() => {
                        setOpenInstructionsModal(true);
                    }}
                >
                    <HelpOutlineIcon color="primary" />
                    &nbsp;Remind me the rules
                </Link>
            </Stack>

            <Tabs
                value={activeTab}
                onChange={(_, value) => {
                    setActiveTab(value);
                    if (value === "top25" && hash === "#my-words") {
                        navigate(PATHS.LANDING, {
                            replace: true,
                        });
                    }
                }}
            >
                <Tab value="top25" label="Top 25 Mindshare" />
                <Tab value="academic" label="Word rankings" />
            </Tabs>
            {activeTab === "top25" && <WordsTreemapChart />}
            {activeTab === "academic" && <WordsTable />}

            <StyledModal
                open={openInstructionsModal}
                aria-labelledby="instructions-modal"
                aria-describedby="game-instructions"
                onClose={handleCloseInstructionsModal}
            >
                <Box>
                    {CONTENT.map((content, index) => (
                        <Paper elevation={3} sx={{ p: 2, mb: 2 }} key={index}>
                            <Typography variant="h6" component="div" mb={1}>
                                {content.title}
                            </Typography>
                            <Typography variant="body1" component="div">
                                {content.content}
                            </Typography>
                        </Paper>
                    ))}
                    <Button
                        variant="contained"
                        onClick={handleCloseInstructionsModal}
                    >
                        Let&apos;s play !
                    </Button>
                </Box>
            </StyledModal>
        </Container>
    );
};

export default Landing;
