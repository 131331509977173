import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    TableCell,
    TableRow,
} from "@mui/material";
import { AxiosError } from "axios";
import { useState } from "react";

import useReservoirClient from "src/hooks/useReservoirClient";
import { TokenBids, getTokenBids } from "src/services/reservoir";
import { CurrentUserToken } from "src/slices/tokenSlice";

import OffersTable from "src/components/TradeModal/OffersTable";

export default function OfferRow(token: CurrentUserToken) {
    const reservoirClient = useReservoirClient();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [tokenBids, setTokenBids] = useState<TokenBids["orders"]>([]);
    const [continuationToken, setContinuationToken] = useState<string | null>(
        null,
    );
    const tokenReservoirId = `${token.token?.contract}:${token.token?.tokenId}`;
    return (
        <>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell sx={{ fontWeight: "bold" }}>
                    {token.token?.name}
                </TableCell>
                <TableCell
                    sx={{ fontWeight: "bold" }}
                >{`$${token.token?.topBid?.price?.amount?.usd}`}</TableCell>
                <TableCell align="right">
                    <Button
                        startIcon={
                            open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )
                        }
                        onClick={async () => {
                            if (!open) {
                                try {
                                    setIsLoading(true);
                                    const data = await getTokenBids({
                                        token: tokenReservoirId,
                                        reservoirClient,
                                        options: {
                                            limit: 50,
                                        },
                                    });
                                    setTokenBids(data.orders);
                                    setContinuationToken(
                                        data.continuation ?? null,
                                    );
                                } catch (_error: unknown) {
                                    const error = _error as AxiosError;
                                    if (
                                        error.message !==
                                        "Cancelled due to new request"
                                    ) {
                                        console.error(
                                            "Error fetching offers",
                                            error.message,
                                        );
                                    }
                                } finally {
                                    setIsLoading(false);
                                }
                            }

                            setOpen(!open);
                        }}
                    >
                        Show all offers
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <OffersTable
                                offers={tokenBids}
                                shouldIncludeAction
                            />
                            {isLoading && (
                                <CircularProgress
                                    sx={{ alignSelf: "center" }}
                                />
                            )}
                            {continuationToken && (
                                <Button
                                    onClick={async () => {
                                        try {
                                            const data = await getTokenBids({
                                                token: tokenReservoirId,
                                                reservoirClient,
                                                options: {
                                                    limit: 50,
                                                    continuation:
                                                        continuationToken,
                                                },
                                            });
                                            setTokenBids((bids) => [
                                                ...(bids ?? []),
                                                ...(data.orders ?? []),
                                            ]);
                                            setContinuationToken(
                                                data.continuation ?? null,
                                            );
                                        } catch (_error: unknown) {
                                            const error = _error as AxiosError;
                                            if (
                                                error.message !==
                                                "Cancelled due to new request"
                                            ) {
                                                console.error(
                                                    "Error fetching offers",
                                                    error.message,
                                                );
                                            }
                                        }
                                    }}
                                >
                                    Load more offers
                                </Button>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
