import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, Typography, useTheme } from "@mui/material";

export default function VariationCell({
    variation,
}: {
    variation: number | null;
}) {
    const theme = useTheme();
    let color = theme.palette.warning.main;
    if (variation) {
        if (variation > 0) {
            color = theme.palette.success.main;
        } else if (variation < 0) {
            color = theme.palette.error.main;
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            className={variation && variation > 500 ? "on-fire" : ""}
        >
            {variation && variation > 500 ? <>🚀&nbsp;</> : null}
            {variation && variation > 0 && variation < 500 ? (
                <ArrowUpwardIcon
                    style={{
                        transform: "rotate(45deg)",
                        color,
                    }}
                />
            ) : null}
            {variation === 0 ? (
                <ArrowUpwardIcon
                    style={{
                        transform: "rotate(90deg)",
                        color,
                    }}
                />
            ) : null}
            {variation && variation < 0 ? (
                <ArrowDownwardIcon
                    style={{
                        transform: "rotate(-45deg)",
                        color,
                    }}
                />
            ) : null}
            {variation === null ? (
                <Typography>-</Typography>
            ) : (
                <Typography color={color}>{Math.abs(variation)}%</Typography>
            )}
            {variation && variation > 500
                ? new Array(50)
                      .fill(null)
                      .map((_, index) => (
                          <span key={index} className="particle"></span>
                      ))
                : null}
        </Box>
    );
}
