import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import { APIError, Execute } from "@reservoir0x/reservoir-sdk";
import { useEffect, useState } from "react";
import { BaseError } from "viem";

import useReservoirClient from "src/hooks/useReservoirClient";
import useTypeWriter from "src/hooks/useTypewriter";
import useWalletClient from "src/hooks/useWalletClient";
import { TokenBids } from "src/services/reservoir";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";
import {
    getTokenById,
    removeCurrentUserTokenById,
} from "src/slices/tokenSlice";

import StyledModal from "src/components/StyledModal";

import { useAppDispatch } from "src/store";
import {
    StepData,
    getMessageFromPostError,
    onProgressAction,
} from "src/utils/reservoir";

enum ActionStep {
    Accept = "Accept",
    Approving = "Approving",
    Complete = "Complete",
}

export default function AcceptOfferButton({
    offer,
}: {
    offer: NonNullable<TokenBids["orders"]>[0];
}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [stepData, setStepData] = useState<StepData | null>(null);
    const [actionStep, setActionStep] = useState<ActionStep>(ActionStep.Accept);

    const [typewriterText, setTypewriterText] = useState("");
    const { currentText: currentTypewriterText } = useTypeWriter({
        text: typewriterText,
        delay: 100,
        infinite: false,
    });

    const dispatch = useAppDispatch();
    const walletClient = useWalletClient();
    const reservoirClient = useReservoirClient();
    const contract = offer?.tokenSetId?.split(":")[1];
    const tokenId = offer?.tokenSetId?.split(":")[2];

    useEffect(() => {
        if (actionStep === ActionStep.Approving && stepData?.currentStepItem) {
            const newText =
                stepData.currentStepItem.txHashes &&
                stepData.currentStepItem.txHashes.length > 0
                    ? "Waiting for transaction to be validated"
                    : "Waiting for approval...";
            setTypewriterText((prev) => (prev !== newText ? newText : prev));
        }
    }, [actionStep, stepData?.currentStepItem]);

    const onCloseModal = () => {
        setModalIsOpen(false);
        setStepData(null);
        setActionStep(ActionStep.Accept);
        setStepData(null);
        // Dispatch to have data updated
        dispatch(
            removeCurrentUserTokenById({
                tokenId: Number(tokenId),
            }),
        );
        dispatch(
            getTokenById({
                reservoirClient,
                tokenId: Number(tokenId),
            }),
        );
    };

    const acceptOffer = async () => {
        try {
            setIsLoading(true);
            setActionStep(ActionStep.Approving);
            setModalIsOpen(true);

            await reservoirClient?.actions.acceptOffer({
                items: [
                    {
                        orderId: offer.id,
                        token: `${contract}:${tokenId}`,
                    },
                ],
                wallet: walletClient,
                onProgress: (steps: Execute["steps"]) =>
                    onProgressAction({
                        steps,
                        setStepData,
                        onComplete: () => setActionStep(ActionStep.Complete),
                    }),
            });
        } catch (_error: unknown) {
            const error = _error as APIError | BaseError;
            const message = getMessageFromPostError(error);
            console.error("Error accepting offer", message, error);
            if (message) {
                dispatch(
                    setSnackbarFeedback({
                        type: SeverityType.ERROR,
                        message,
                    }),
                );
            }
            onCloseModal();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button
                variant="contained"
                onClick={acceptOffer}
                disabled={isLoading}
                size="small"
                fullWidth
            >
                Accept
            </Button>
            {modalIsOpen && (
                <StyledModal
                    open={modalIsOpen}
                    onClose={() =>
                        actionStep !== ActionStep.Approving
                            ? onCloseModal()
                            : undefined
                    }
                    aria-labelledby="accept-offer-dialog-title"
                    aria-describedby="accept-offer-dialog-description"
                >
                    <Stack>
                        <DialogTitle id="accept-offer-dialog-title">
                            Accepting offer
                        </DialogTitle>
                        <DialogContent>
                            {actionStep === ActionStep.Approving ? (
                                <Typography>{currentTypewriterText}</Typography>
                            ) : null}
                            {actionStep === ActionStep.Complete ? (
                                <Typography>Offer accepted!</Typography>
                            ) : null}
                        </DialogContent>
                        <DialogActions>
                            {actionStep === ActionStep.Complete ? (
                                <Button
                                    onClick={onCloseModal}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            ) : null}
                        </DialogActions>
                    </Stack>
                </StyledModal>
            )}
        </>
    );
}
