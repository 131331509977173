import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { useContext, useEffect } from "react";

import useReservoirClient from "src/hooks/useReservoirClient";
import {
    getCurrentUserTokens,
    selectCurrentUserTokenWithBidsToAccept,
} from "src/slices/tokenSlice";

import OfferRow from "src/components/OfferRow";

import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";

const Offers = () => {
    const { selectedAccount } = useContext(WalletContext);
    const dispatch = useAppDispatch();
    const reservoirClient = useReservoirClient();
    const tokens = useAppSelector(selectCurrentUserTokenWithBidsToAccept);
    const theme = useTheme();
    const headerCellSX = {
        fontWeight: "bold",
        color: theme.palette.secondary.main,
    };

    useEffect(() => {
        if (selectedAccount && reservoirClient) {
            dispatch(
                getCurrentUserTokens({
                    reservoirClient,
                    address: selectedAccount,
                }),
            );
        }
    }, [dispatch, reservoirClient, selectedAccount]);

    return (
        <Container
            maxWidth="xl"
            sx={{
                flex: 1,
                paddingBottom: 2,
            }}
        >
            <Typography variant="h4" mb={2}>
                Offers received
            </Typography>
            {tokens?.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerCellSX}>Word</TableCell>
                            <TableCell sx={headerCellSX}>
                                Top offer price
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tokens?.map((token) => (
                            <OfferRow
                                key={`row-token-id-${token.token?.tokenId}`}
                                token={token.token}
                            />
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Typography>No offer yet</Typography>
            )}
        </Container>
    );
};

export default Offers;
