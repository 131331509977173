export const chunkArray = (array: number[], chunkSize: number) => {
    const result: number[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
};

export async function waitFor(
    intervalTime: number,
    releaseCallback = async () => true,
) {
    return new Promise<void>((resolve, reject) => {
        const interval = setInterval(async () => {
            try {
                const canBeReleased = await releaseCallback();

                if (canBeReleased) {
                    clearInterval(interval);
                    resolve();
                }
            } catch (e) {
                clearInterval(interval);
                reject(e);
            }
        }, intervalTime);
    });
}
