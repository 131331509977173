import {
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
    getCurrentUserWords,
    selectCurrentUserWordsCount,
} from "src/slices/wordSlice";

import { PATHS } from "src/constants";
import { ContractContext } from "src/contexts/ContractsContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";

export default function WordsSelectionToggle() {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { contract } = useContext(ContractContext);

    const { selectedAccount } = useContext(WalletContext);
    const currentUserWordsCount = useAppSelector(selectCurrentUserWordsCount);

    const handleChange = (
        _event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        if (newAlignment === "mine") {
            if (contract && selectedAccount) {
                dispatch(
                    getCurrentUserWords({ contract, address: selectedAccount }),
                );
            }

            navigate(`${PATHS.LANDING}#my-words`, {
                replace: true,
            });
        } else {
            navigate(PATHS.LANDING, {
                replace: true,
            });
        }
    };

    const control = {
        value: hash === "#my-words" ? "mine" : "all",
        onChange: handleChange,
        exclusive: true,
    };

    return (
        <Stack direction="row">
            <ToggleButtonGroup size="small" {...control}>
                <ToggleButton value="all" key="all" color="primary">
                    <Typography>All words</Typography>
                </ToggleButton>
                <ToggleButton
                    color="primary"
                    value="mine"
                    key="mine"
                    disabled={currentUserWordsCount === 0 || !selectedAccount}
                >
                    <Typography>Mine ({currentUserWordsCount})</Typography>
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>
    );
}
