import { Typography, TypographyProps } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { CURRENCY_SYMBOL } from "src/constants";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { formatCurrency } from "src/utils/formatCurrency";

type ExtendedTypographyProps = TypographyProps & {
    amount: bigint;
    toFixed?: number;
    currencySymbol?: CURRENCY_SYMBOL.USD | CURRENCY_SYMBOL.ETH;
};

function CurrencyDisplay({
    amount, // Should always be in WEI
    toFixed,
    currencySymbol = CURRENCY_SYMBOL.USD,
    ...props
}: ExtendedTypographyProps) {
    const [displayAmount, setDisplayAmount] = useState<string>("???");
    const { rate } = useContext(CurrencyRateContext);

    useEffect(() => {
        if (amount == null || isNaN(Number(amount))) {
            console.warn("amount is null or NaN", amount);

            setDisplayAmount("???");
            return;
        }

        async function convertAmountToTargetCurrency(amount: bigint) {
            try {
                const amountReadyToDisplay = await formatCurrency({
                    valueToConvert: amount,
                    currencySymbol,
                    toFixed,
                    rate,
                });
                setDisplayAmount(amountReadyToDisplay);
            } catch (error) {
                console.error(error);
            }
        }

        convertAmountToTargetCurrency(amount);
    }, [amount, currencySymbol, toFixed, rate]);

    return (
        <Typography {...props} variant={props?.variant || "inherit"}>
            {displayAmount}
        </Typography>
    );
}

export default CurrencyDisplay;
