import {
    PropsWithChildren,
    createContext,
    useCallback,
    useEffect,
    useState,
} from "react";

import { getCurrencyRate } from "src/services/api";

export interface CurrencyRateContextType {
    rate: number | null;
}

export const CurrencyRateContext = createContext<CurrencyRateContextType>(
    null as unknown as CurrencyRateContextType,
);
export const CurrencyRateProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const [currencyRate, setCurrencyRate] = useState<number | null>(null);

    const fetchCurrencyRate = useCallback(async () => {
        if (!currencyRate) {
            const rate = await getCurrencyRate();
            setCurrencyRate(rate);
        }
    }, [currencyRate]);
    useEffect(() => {
        fetchCurrencyRate();
    }, [fetchCurrencyRate]);

    return (
        <CurrencyRateContext.Provider value={{ rate: currencyRate }}>
            {children}
        </CurrencyRateContext.Provider>
    );
};
