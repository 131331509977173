import { Stack } from "@mui/material";

import useReservoirClient from "src/hooks/useReservoirClient";
import {
    Token,
    getTokenById,
    removeCurrentUserBidByTokenId,
    selectCurrentUserBidByTokenId,
} from "src/slices/tokenSlice";

import CancelOrderButton from "src/components/TradeModal/CancelOrderButton";
import MakeOfferButton from "src/components/TradeModal/MakeOfferButton";

import { useAppDispatch, useAppSelector } from "src/store";

import OffersTable from "./OffersTable";

const TradeModalMyOfferContent = ({ token }: { token: Token }) => {
    const dispatch = useAppDispatch();
    const reservoirClient = useReservoirClient();

    const tokenId = token.token?.tokenId;
    const currentUserBid = useAppSelector(
        selectCurrentUserBidByTokenId(Number(tokenId)),
    );

    return (
        <Stack pt={2} pb={2} gap={2}>
            <OffersTable offers={[currentUserBid]} />
            {currentUserBid && <MakeOfferButton token={token} />}
            {currentUserBid.id && tokenId && (
                <CancelOrderButton
                    tokenId={Number(tokenId)}
                    modalTitle="Delete offer"
                    completeText="Your offer has been succesfully deleted"
                    orderId={currentUserBid.id}
                    onCloseAction={() => {
                        dispatch(
                            removeCurrentUserBidByTokenId({
                                tokenId: Number(tokenId),
                            }),
                        );
                        dispatch(
                            getTokenById({
                                reservoirClient,
                                tokenId: Number(tokenId),
                            }),
                        );
                    }}
                />
            )}
        </Stack>
    );
};

export default TradeModalMyOfferContent;
