import { Address } from "viem";

export const truncate = (
    text: string,
    startChars: number,
    endChars: number,
) => {
    return `${text.slice(0, startChars)}...${text.slice(-endChars)}`;
};

export const truncateAddress = (address: Address) => {
    return truncate(address, 6, 4);
};
