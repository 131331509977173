import {
    PayloadAction,
    createAsyncThunk,
    createSelector,
    createSlice,
} from "@reduxjs/toolkit";
import { ReservoirClient, paths } from "@reservoir0x/reservoir-sdk";
import { AxiosError } from "axios";
import { Address } from "viem";

import {
    UserBids,
    UserTokenResponse,
    getTokens,
    getUserBids,
    getUserTokens,
} from "src/services/reservoir";

import { MARKET_PLACE_CONTRACT_ADDRESS } from "src/constants";
import { RootState } from "src/store";
import { chunkArray } from "src/utils/common";

export type Token = NonNullable<
    paths["/tokens/v7"]["get"]["responses"]["200"]["schema"]["tokens"]
>[0];
export type CurrentUserToken = NonNullable<UserTokenResponse["tokens"]>[0];
type CurrentUserBid = NonNullable<UserBids["orders"]>[0];
interface ITokenState {
    listedTokenIds: number[];
    currentUserTokenIds: number[];
    currentUserTokenIdsWithBidsToAccept: number[];
    tokenIdsWithCurrrentUserBid: number[];
    tokensById: Record<number, Token>;
    currentUserTokensById: Record<number, CurrentUserToken>;
    currentUserBidByTokenId: Record<number, CurrentUserBid>;
    statuses: Record<
        | "getTokensByIds"
        | "getCurrentUserTokens"
        | "getCurrentUserBids"
        | "getTokenById",
        "idle" | "loading" | "succeeded" | "failed"
    >;
    error: string | null;
}

const initialState: ITokenState = {
    listedTokenIds: [],
    currentUserTokenIds: [],
    currentUserTokenIdsWithBidsToAccept: [],
    tokenIdsWithCurrrentUserBid: [],
    tokensById: {},
    currentUserTokensById: {},
    currentUserBidByTokenId: {},
    statuses: {
        getTokensByIds: "idle",
        getCurrentUserTokens: "idle",
        getCurrentUserBids: "idle",
        getTokenById: "idle",
    },
    error: null,
};

// Thunk for fetching a token by id
export const getTokenById = createAsyncThunk<
    Token | undefined,
    { tokenId: number; reservoirClient?: ReservoirClient | null },
    { rejectValue: string }
>("token/getTokenById", async ({ tokenId, reservoirClient }, thunkAPI) => {
    try {
        const data = await getTokens({
            reservoirClient,
            options: {
                tokens: [`${MARKET_PLACE_CONTRACT_ADDRESS}:${tokenId}`],
                includeTopBid: true,
            },
        });
        return data.tokens?.[0];
    } catch (_error: unknown) {
        const error = _error as AxiosError;
        console.warn("Error while fetching token: ", error);
        return thunkAPI.rejectWithValue(error.message);
    }
});

// Thunk for fetching multiple tokens by ids
export const getTokensByIds = createAsyncThunk<
    Token[] | undefined,
    { tokenIds: number[]; reservoirClient?: ReservoirClient | null },
    { rejectValue: string }
>("token/getTokensByIds", async ({ tokenIds, reservoirClient }, thunkAPI) => {
    try {
        const allTokens: Token[] = [];
        const tokenChunks = chunkArray(tokenIds, 50); // 50 is the max tokenIds size allowed, see https://docs.reservoir.tools/reference/gettokensv7

        for (const chunk of tokenChunks) {
            const data = await getTokens({
                reservoirClient,
                options: {
                    tokens: chunk.map(
                        (id) => `${MARKET_PLACE_CONTRACT_ADDRESS}:${id}`,
                    ),
                    includeTopBid: true,
                    limit: chunk.length,
                },
            });
            if (data.tokens) {
                allTokens.push(...data.tokens);
            }
        }

        return allTokens;
    } catch (_error: unknown) {
        const error = _error as AxiosError;
        console.warn("Error while fetching tokens: ", error);
        return thunkAPI.rejectWithValue(error.message);
    }
});

// Thunk for fetching tokens owned by specific address
export const getCurrentUserTokens = createAsyncThunk<
    UserTokenResponse["tokens"] | undefined,
    { address: Address; reservoirClient?: ReservoirClient | null },
    { rejectValue: string }
>(
    "token/getCurrentUserTokens",
    async ({ address, reservoirClient }, thunkAPI) => {
        try {
            let continuationToken: string | boolean | undefined = true;
            let tokens: UserTokenResponse["tokens"] = [];
            while (continuationToken) {
                const data = await getUserTokens({
                    reservoirClient,
                    address,
                    options: {
                        collection: MARKET_PLACE_CONTRACT_ADDRESS,
                        includeTopBid: true,
                        limit: 200,
                    },
                });
                tokens = [...(tokens ?? []), ...(data.tokens ?? [])];
                continuationToken = data.continuation;
            }
            return tokens;
        } catch (_error: unknown) {
            const error = _error as AxiosError;
            console.warn("Error while fetching current user tokens: ", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

// Thunk for fetching bids made by specific address
export const getCurrentUserBids = createAsyncThunk<
    UserBids["orders"] | undefined,
    { address: Address; reservoirClient?: ReservoirClient | null },
    { rejectValue: string }
>(
    "token/getCurrentUserBids",
    async ({ address, reservoirClient }, thunkAPI) => {
        try {
            let continuationToken: string | boolean | undefined = true;
            let bids: UserBids["orders"] = [];
            while (continuationToken) {
                const data = await getUserBids({
                    reservoirClient,
                    address,
                    options: {
                        limit: 50,
                        collection: MARKET_PLACE_CONTRACT_ADDRESS,
                        continuation:
                            typeof continuationToken === "string"
                                ? continuationToken
                                : undefined,
                    },
                });
                bids = [...(bids ?? []), ...(data.orders ?? [])];
                continuationToken = data.continuation;
            }
            return bids;
        } catch (_error: unknown) {
            const error = _error as AxiosError;
            console.warn("Error while fetching bids by maker address: ", error);
            return thunkAPI.rejectWithValue(error.message);
        }
    },
);

export const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        removeCurrentUserBidByTokenId: (
            state,
            { payload }: PayloadAction<{ tokenId: number }>,
        ) => {
            state.tokenIdsWithCurrrentUserBid =
                state.tokenIdsWithCurrrentUserBid.filter(
                    (id) => id !== payload.tokenId,
                );
            delete state.currentUserBidByTokenId[payload.tokenId];
        },
        removeCurrentUserTokenById: (
            state,
            { payload }: PayloadAction<{ tokenId: number }>,
        ) => {
            state.currentUserTokenIds = state.currentUserTokenIds.filter(
                (id) => id !== payload.tokenId,
            );
            state.currentUserTokenIdsWithBidsToAccept =
                state.currentUserTokenIdsWithBidsToAccept.filter(
                    (id) => id !== payload.tokenId,
                );
            delete state.currentUserTokensById[payload.tokenId];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTokenById.fulfilled, (state, { payload }) => {
                const listedIds = state.listedTokenIds;
                if (payload?.token?.tokenId) {
                    state.tokensById[Number(payload.token.tokenId)] = payload;
                    if (payload?.market?.floorAsk?.maker) {
                        listedIds.push(Number(payload.token.tokenId));
                    }
                }
                state.listedTokenIds = [...new Set(listedIds)];
                state.statuses.getTokenById = "succeeded";
            })
            .addCase(getTokenById.pending, (state) => {
                state.statuses.getTokenById = "loading";
                state.error = null;
            })
            .addCase(getTokenById.rejected, (state, { error }) => {
                state.error = error.message as string;
                state.statuses.getTokenById = "failed";
            })
            .addCase(getTokensByIds.fulfilled, (state, { payload }) => {
                const listedIds = state.listedTokenIds;
                payload?.forEach((data) => {
                    if (data.token?.tokenId) {
                        state.tokensById[Number(data.token.tokenId)] = data;
                    }
                    if (data.market?.floorAsk?.maker && data.token?.tokenId) {
                        listedIds.push(Number(data.token.tokenId));
                    }
                });
                state.listedTokenIds = [...new Set(listedIds)];
                state.statuses.getTokensByIds = "succeeded";
            })
            .addCase(getTokensByIds.pending, (state) => {
                state.statuses.getTokensByIds = "loading";
                state.error = null;
            })
            .addCase(getTokensByIds.rejected, (state, { error }) => {
                state.error = error.message as string;
                state.statuses.getTokensByIds = "failed";
            })
            .addCase(getCurrentUserTokens.fulfilled, (state, { payload }) => {
                const currentUserTokenIds = state.currentUserTokenIds;
                const currentUserTokenIdsWithBidsToAccept =
                    state.currentUserTokenIdsWithBidsToAccept;
                payload?.forEach((data) => {
                    if (data.token?.tokenId) {
                        state.currentUserTokensById[
                            Number(data.token.tokenId)
                        ] = data;
                        currentUserTokenIds.push(Number(data.token.tokenId));
                        if (data.token.topBid?.id) {
                            currentUserTokenIdsWithBidsToAccept.push(
                                Number(data.token.tokenId),
                            );
                        }
                    }
                });
                state.currentUserTokenIdsWithBidsToAccept = [
                    ...new Set(currentUserTokenIdsWithBidsToAccept),
                ];
                state.currentUserTokenIds = [...new Set(currentUserTokenIds)];
                state.statuses.getCurrentUserTokens = "succeeded";
            })
            .addCase(getCurrentUserTokens.pending, (state) => {
                state.statuses.getCurrentUserTokens = "loading";
                state.error = null;
            })
            .addCase(getCurrentUserTokens.rejected, (state, { error }) => {
                state.error = error.message as string;
                state.statuses.getCurrentUserTokens = "failed";
            })
            .addCase(getCurrentUserBids.fulfilled, (state, { payload }) => {
                const tokenIdsWithCurrrentUserBid =
                    state.tokenIdsWithCurrrentUserBid;

                payload?.forEach((bid) => {
                    const tokenId = bid.tokenSetId.split(":")[2];
                    if (tokenId) {
                        tokenIdsWithCurrrentUserBid.push(Number(tokenId));
                        state.currentUserBidByTokenId[Number(tokenId)] = bid;
                    }
                });
                state.tokenIdsWithCurrrentUserBid = [
                    ...new Set(tokenIdsWithCurrrentUserBid),
                ];
                state.statuses.getCurrentUserBids = "succeeded";
            })
            .addCase(getCurrentUserBids.pending, (state) => {
                state.statuses.getCurrentUserBids = "loading";
                state.error = null;
            })
            .addCase(getCurrentUserBids.rejected, (state, { error }) => {
                state.error = error.message as string;
                state.statuses.getCurrentUserBids = "failed";
            });
    },
});

export const { removeCurrentUserBidByTokenId, removeCurrentUserTokenById } =
    tokenSlice.actions;

const selectTokenState = (state: RootState) => state.token;

export const selectTokenById = (tokenId?: number) =>
    createSelector(selectTokenState, (state) => {
        return tokenId ? state.tokensById[tokenId] : undefined;
    });

export const selectCurrentUserTokenIdsWithBidsToAccept = createSelector(
    selectTokenState,
    (state) => {
        return state.currentUserTokenIdsWithBidsToAccept;
    },
);

const selectCurrentUserTokensById = (state: RootState) =>
    state.token.currentUserTokensById;

export const selectCurrentUserTokenWithBidsToAccept = createSelector(
    [selectCurrentUserTokenIdsWithBidsToAccept, selectCurrentUserTokensById],
    (currentUserTokenIdsWithBidsToAccept, currentUserTokensById) => {
        return currentUserTokenIdsWithBidsToAccept.map(
            (tokenId) => currentUserTokensById[tokenId],
        );
    },
);

export const selectCurrentUserBidByTokenId = (tokenId: number) =>
    createSelector(selectTokenState, (state) => {
        return state.currentUserBidByTokenId[tokenId];
    });

export const selectTokenStatuses = (state: RootState) => state.token.statuses;

export default tokenSlice.reducer;
