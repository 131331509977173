import {
    Box,
    Button,
    CircularProgress,
    Stack,
    Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";

import useReservoirClient from "src/hooks/useReservoirClient";
import { TokenBids, getTokenBids } from "src/services/reservoir";
import { Token } from "src/slices/tokenSlice";

import OffersTable from "src/components/TradeModal/OffersTable";
import ReservoirPrice from "src/components/TradeModal/ReservoirPrice";

import { WalletContext } from "src/contexts/WalletContext";

const TradeModalOffersContent = ({ token }: { token: Token }) => {
    const topBid = token.market?.topBid;
    const { selectedAccount } = useContext(WalletContext);
    const [isLoading, setIsLoading] = useState(true);
    const [continuationToken, setContinuationToken] = useState<string | null>(
        null,
    );

    const reservoirClient = useReservoirClient();
    const tokenOwer = token?.token?.owner?.toLowerCase();
    const tokenIsOwnedByCurrentUser =
        tokenOwer === selectedAccount?.toLowerCase();

    const [tokenBids, setTokenBids] = useState<TokenBids["orders"]>([]);

    const tokenReservoirId = `${token.token?.contract}:${token.token?.tokenId}`;

    useEffect(() => {
        const fetchTokenBids = async (token: string) => {
            try {
                setIsLoading(true);
                const data = await getTokenBids({
                    token,
                    reservoirClient,
                    options: {
                        limit: 50,
                    },
                });
                setTokenBids(data.orders);
                setContinuationToken(data.continuation ?? null);
            } catch (_error: unknown) {
                const error = _error as AxiosError;
                if (error.message !== "Cancelled due to new request") {
                    console.error("Error fetching offers", error.message);
                }
            } finally {
                setIsLoading(false);
            }
        };
        fetchTokenBids(tokenReservoirId);
    }, [reservoirClient, tokenReservoirId]);

    return (
        <Stack pt={2} pb={2} gap={2} height="100%" flex={1}>
            {topBid?.price ? (
                <ReservoirPrice
                    price={topBid.price}
                    leftText="Highest offer:"
                />
            ) : null}
            {isLoading && (
                <CircularProgress size={25} sx={{ alignSelf: "center" }} />
            )}
            {!isLoading && !tokenBids?.length && (
                <Typography>No offers yet</Typography>
            )}
            {!isLoading && tokenBids && tokenBids.length > 0 && (
                <Box flex={1} overflow="scroll">
                    <OffersTable
                        offers={tokenBids}
                        shouldIncludeAction={tokenIsOwnedByCurrentUser}
                    />
                </Box>
            )}

            {continuationToken && (
                <Button
                    onClick={async () => {
                        try {
                            const data = await getTokenBids({
                                token: tokenReservoirId,
                                reservoirClient,
                                options: {
                                    limit: 50,
                                    continuation: continuationToken,
                                },
                            });
                            setTokenBids((bids) => [
                                ...(bids ?? []),
                                ...(data.orders ?? []),
                            ]);
                            setContinuationToken(data.continuation ?? null);
                        } catch (_error: unknown) {
                            const error = _error as AxiosError;
                            if (
                                error.message !== "Cancelled due to new request"
                            ) {
                                console.error(
                                    "Error fetching offers",
                                    error.message,
                                );
                            }
                        }
                    }}
                >
                    Load more offers
                </Button>
            )}
        </Stack>
    );
};

export default TradeModalOffersContent;
