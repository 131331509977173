import {
    createReduxMiddleware,
    defaultOptions,
} from "@karmaniverous/serify-deserify";
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector, useStore } from "react-redux";

import contractSettingsReducer from "src/slices/contractSettingsSlice";
import snackbarFeedbackReducer from "src/slices/snackbarFeedbackSlice";
import tokenReducer from "src/slices/tokenSlice";
import wordChartDataReducer from "src/slices/wordChartDataSlice";
import wordReducer from "src/slices/wordSlice";

const serifyMiddleware = createReduxMiddleware(defaultOptions);

export const store = configureStore({
    reducer: {
        snackbarFeedback: snackbarFeedbackReducer,
        contractSettings: contractSettingsReducer,
        word: wordReducer,
        wordChartData: wordChartDataReducer,
        token: tokenReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(serifyMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();
