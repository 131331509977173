import {
    Button,
    CircularProgress,
    DialogContent,
    DialogTitle,
    ModalProps,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import useReservoirClient from "src/hooks/useReservoirClient";
import { getTokenById, selectTokenById } from "src/slices/tokenSlice";

import SellToContractInfoAndButton from "src/components/SellToContractInfoAndButton";
import StyledModal from "src/components/StyledModal";
import ListForSaleButton from "src/components/TradeModal/ListForSaleButton";

import { useAppDispatch, useAppSelector } from "src/store";
import { waitFor } from "src/utils/common";

type SellModalProps = Omit<ModalProps, "children" | "onClose"> & {
    title: string;
    tokenId: number;
    onClose: () => void;
};

export default function SellModal({
    onClose,
    open,
    title,
    tokenId,
    ...props
}: SellModalProps) {
    const [disableCloseModal, setDisableCloseModal] = useState(false);
    const [errorGettingTokenData, setErrorGettingTokenData] = useState(false);
    const dispatch = useAppDispatch();

    const token = useAppSelector(selectTokenById(tokenId));
    const reservoirClient = useReservoirClient();

    useEffect(() => {
        const waitForDataToBeUpdated = async () => {
            const startOfWait = new Date();
            await waitFor(2_000, async () => {
                if (new Date().getTime() - startOfWait.getTime() > 15_000) {
                    setErrorGettingTokenData(true);
                    return true;
                }
                dispatch(
                    getTokenById({
                        reservoirClient,
                        tokenId: Number(tokenId),
                    }),
                );
                return Boolean(token);
            });
        };
        if (!token && tokenId) {
            waitForDataToBeUpdated();
        }
    }, [dispatch, token, reservoirClient, tokenId]);

    return (
        <StyledModal
            {...props}
            open={open}
            onClose={disableCloseModal ? () => {} : onClose}
            aria-labelledby="sell-word-dialog-title"
            aria-describedby="sell-word-dialog-description"
        >
            <Stack>
                <DialogTitle id="sell-word-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <Stack gap={1}>
                        <SellToContractInfoAndButton
                            tokenId={tokenId}
                            setDisableCloseModal={setDisableCloseModal}
                            onCloseSellModal={onClose}
                        />
                        {!token && !errorGettingTokenData && (
                            <CircularProgress sx={{ alignSelf: "center" }} />
                        )}
                        {!token && errorGettingTokenData && (
                            <Typography>
                                Can't list for sale for now, please try again
                                later
                            </Typography>
                        )}
                        {token && (
                            <ListForSaleButton
                                token={token}
                                onCloseParentModal={onClose}
                            />
                        )}
                        <Button disabled={disableCloseModal} onClick={onClose}>
                            Cancel
                        </Button>
                    </Stack>
                </DialogContent>
            </Stack>
        </StyledModal>
    );
}
