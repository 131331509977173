import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from "@mui/material";
import {
    APIError,
    Execute,
    ListTokenBody,
    definitions,
    paths,
} from "@reservoir0x/reservoir-sdk";
import { AxiosError } from "axios";
import { addMonths, getUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { BaseError, parseEther } from "viem";

import useReservoirClient from "src/hooks/useReservoirClient";
import useTypeWriter from "src/hooks/useTypewriter";
import useWalletClient from "src/hooks/useWalletClient";
import { getListings } from "src/services/reservoir";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";
import { Token, getTokenById } from "src/slices/tokenSlice";

import StyledModal from "src/components/StyledModal";
import AmountInput from "src/components/TradeModal/AmountInput";

import { useAppDispatch } from "src/store";
import {
    StepData,
    getMessageFromPostError,
    onProgressAction,
} from "src/utils/reservoir";

type Listing = NonNullable<
    paths["/orders/asks/v5"]["get"]["responses"]["200"]["schema"]["orders"]
>[0];

type OrderKind = Required<ListTokenBody>["params"][0]["orderKind"];

enum ActionStep {
    ApprovingCancel = "ApprovingCancel",
    SetPrice = "SetPrice",
    Approving = "Approving",
    Complete = "Complete",
}

export default function ListForSaleButton({
    token,
    onCloseParentModal,
}: {
    token: Token;
    onCloseParentModal?: () => void;
}) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ethPrice, setETHPrice] = useState(
        token?.market?.floorAsk?.price?.amount?.decimal?.toString() ?? "",
    );
    const [listing, setListing] = useState<Listing | null>(null);
    const [stepData, setStepData] = useState<StepData | null>(null);
    const [actionStep, setActionStep] = useState<ActionStep>(
        ActionStep.SetPrice,
    );
    const [typewriterText, setTypewriterText] = useState("");
    const { currentText: currentTypewriterText } = useTypeWriter({
        text: typewriterText,
        delay: 100,
        infinite: false,
    });

    const dispatch = useAppDispatch();
    const walletClient = useWalletClient();

    const reservoirClient = useReservoirClient();
    const tokenIsListed = Boolean(token?.market?.floorAsk?.id);
    const listingId = token?.market?.floorAsk?.id;
    const isOracleOrder = listing?.isNativeOffChainCancellable as boolean;
    const tokenId = token.token?.tokenId;

    const onCloseModal = () => {
        setModalIsOpen(false);
        setStepData(null);
        setActionStep(ActionStep.SetPrice);
        setStepData(null);
        // Dispatch to have data updated
        dispatch(
            getTokenById({
                reservoirClient,
                tokenId: Number(tokenId),
            }),
        );
        if (onCloseParentModal) {
            onCloseParentModal();
        }
    };

    useEffect(() => {
        if (
            [ActionStep.Approving, ActionStep.ApprovingCancel].includes(
                actionStep,
            ) &&
            stepData?.currentStepItem
        ) {
            const newText =
                stepData.currentStepItem.txHashes &&
                stepData.currentStepItem.txHashes.length > 0
                    ? "Waiting for transaction to be validated"
                    : "Waiting for approval...";
            setTypewriterText((prev) => (prev !== newText ? newText : prev));
        }
    }, [actionStep, stepData?.currentStepItem]);

    const openModal = async () => {
        setActionStep(ActionStep.SetPrice);
        if (listingId) {
            try {
                // We get listing to have data needed for update
                const listings = await getListings({
                    reservoirClient,
                    options: {
                        ids: [listingId], // we ask only the floor listing, even if it's possible that the token has other listings
                        maker: walletClient.account.address,
                    },
                });
                if (listings.orders?.[0]) {
                    setListing(listings.orders[0]);
                }
            } catch (_error: unknown) {
                const error = _error as AxiosError;
                console.error("Error fetching listing", error.message);
            }
        }

        setModalIsOpen(true);
    };

    const isUpdate = listing && isOracleOrder; // in this case we can update the listing direclty
    const listToken = async () => {
        try {
            setIsLoading(true);
            if (listing && !isOracleOrder) {
                // if listing is not an oracle order, we need to cancel it before
                setActionStep(ActionStep.ApprovingCancel);
                await reservoirClient?.actions.cancelOrder({
                    ids: [listing.id], // this function can also be used to cancel bids with ids: [bidId]
                    wallet: walletClient,
                    onProgress: (steps: Execute["steps"]) =>
                        onProgressAction({
                            steps,
                            setStepData,
                            onComplete: () =>
                                setActionStep(ActionStep.Approving),
                        }),
                });
            }
            let options: definitions["options"] = {
                "seaport-v1.5": {
                    useOffChainCancellation: true,
                },
            };
            let orderKind: OrderKind = "seaport-v1.5";
            if (isUpdate) {
                orderKind = listing.kind as OrderKind;
                options = {
                    [listing.kind]: {
                        useOffChainCancellation: true,
                        replaceOrderId: listing.id,
                    },
                };
            }
            setActionStep((prev) =>
                prev !== ActionStep.Approving ? ActionStep.Approving : prev,
            );
            await reservoirClient?.actions.listToken({
                listings: [
                    {
                        token: `${token.token?.contract}:${tokenId}`,
                        weiPrice: parseEther(ethPrice).toString(),
                        orderbook: "reservoir",
                        orderKind,
                        expirationTime: getUnixTime(
                            addMonths(new Date(), 1),
                        ).toString(),
                        options,
                        marketplaceFees: reservoirClient.marketplaceFees,
                    },
                ],
                wallet: walletClient,
                onProgress: (steps: Execute["steps"]) =>
                    onProgressAction({
                        steps,
                        setStepData,
                        onComplete: () => setActionStep(ActionStep.Complete),
                    }),
            });
        } catch (_error: unknown) {
            const error = _error as APIError | BaseError;
            const message = getMessageFromPostError(error);
            console.error("Error listing token for sale", message, error);
            if (message) {
                dispatch(
                    setSnackbarFeedback({
                        type: SeverityType.ERROR,
                        message,
                    }),
                );
            }
            onCloseModal();
        } finally {
            setIsLoading(false);
        }
    };

    let modalTitle = `List "${token?.token?.name || `#${token?.token?.tokenId}`}" for sale`;
    if (tokenIsListed) {
        modalTitle = `Update listing for "${token?.token?.name || `#${token?.token?.tokenId}`}"`;
    }
    return (
        <>
            <Button variant="contained" onClick={openModal}>
                {tokenIsListed ? "Update" : "List for sale"}
            </Button>
            {modalIsOpen && (
                <StyledModal
                    open={modalIsOpen}
                    onClose={() =>
                        ![
                            ActionStep.Approving,
                            ActionStep.ApprovingCancel,
                        ].includes(actionStep)
                            ? onCloseModal()
                            : undefined
                    }
                    aria-labelledby="list-for-sale-dialog-title"
                    aria-describedby="list-for-sale-dialog-description"
                >
                    <Stack>
                        <DialogTitle id="list-for-sale-dialog-title">
                            {modalTitle}
                        </DialogTitle>

                        <DialogContent>
                            {actionStep === ActionStep.SetPrice ? (
                                <AmountInput
                                    onChange={(e) => {
                                        setETHPrice(e.target.value);
                                    }}
                                    value={ethPrice}
                                    showUnit
                                />
                            ) : null}
                            {[
                                ActionStep.Approving,
                                ActionStep.ApprovingCancel,
                            ].includes(actionStep) ? (
                                <Typography>{currentTypewriterText}</Typography>
                            ) : null}
                            {actionStep === ActionStep.Complete ? (
                                <Typography>
                                    {listing
                                        ? "Your listing has been updated!"
                                        : "Your word has been listed!"}
                                </Typography>
                            ) : null}
                        </DialogContent>

                        <DialogActions>
                            {actionStep === ActionStep.Complete ? (
                                <Button
                                    onClick={onCloseModal}
                                    color="secondary"
                                    variant="contained"
                                >
                                    Close
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        onClick={onCloseModal}
                                        color="secondary"
                                        disabled={
                                            isLoading ||
                                            [
                                                ActionStep.Approving,
                                                ActionStep.ApprovingCancel,
                                            ].includes(actionStep)
                                        }
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={listToken}
                                        autoFocus
                                        color="primary"
                                        variant="contained"
                                        disabled={
                                            isLoading ||
                                            !ethPrice.length ||
                                            Number(ethPrice) === 0 ||
                                            [
                                                ActionStep.Approving,
                                                ActionStep.ApprovingCancel,
                                            ].includes(actionStep)
                                        }
                                    >
                                        Confirm
                                    </Button>
                                </>
                            )}
                        </DialogActions>
                    </Stack>
                </StyledModal>
            )}
        </>
    );
}
