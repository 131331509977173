import { PropsWithChildren, createContext } from "react";

import useContract, { ContractType } from "src/hooks/useContract";

export interface ContractContextType {
    contract?: ContractType;
    error: string | null;
    loading: boolean;
}

export const ContractContext = createContext<ContractContextType>(
    null as unknown as ContractContextType,
);
export const ContractProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const contractContextValue = useContract();

    return (
        <ContractContext.Provider value={contractContextValue}>
            {children}
        </ContractContext.Provider>
    );
};
