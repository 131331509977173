import {
    Box,
    Button,
    CircularProgress,
    Stack,
    Typography,
} from "@mui/material";
import { differenceInHours } from "date-fns";
import { useContext, useEffect, useState } from "react";

import usePublicClient from "src/hooks/usePublicClient";
import useSpinningWheel from "src/hooks/useSpinningWheel";
import useTypeWriter from "src/hooks/useTypewriter";
import {
    getRandomTokenPrice,
    getWordsOpenToBeBought,
    selectBuyPrice,
    selectContractSettingsStatuses,
    selectIsContractStateOpen,
    selectNextWordIntroductionData,
    selectWordsOpenToBeBought,
} from "src/slices/contractSettingsSlice";
import {
    SeverityType,
    setSnackbarFeedback,
} from "src/slices/snackbarFeedbackSlice";
import {
    getCurrentUserWords,
    selectCurrentUserWords,
    selectCurrentUserWordsCount,
} from "src/slices/wordSlice";

import ConnectButton from "src/components/ConnectButton";
import NextWordIntroductionCountdown from "src/components/NextWordIntroductionCountdown";
import SellModal from "src/components/SellModal";
import SpinningWheel from "src/components/SpinningWheel";

import { TYPEWRITER_TEXT } from "src/constants";
import { ContractContext } from "src/contexts/ContractsContext";
import { CurrencyRateContext } from "src/contexts/CurrencyRateContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";
import { formatRemainingTime } from "src/utils/formatDate";

const BuyWord = () => {
    const dispatch = useAppDispatch();
    const { selectedAccount } = useContext(WalletContext);
    const { contract } = useContext(ContractContext);
    const { rate } = useContext(CurrencyRateContext);
    const wordsOpenToBeBought = useAppSelector(selectWordsOpenToBeBought);
    const statuses = useAppSelector(selectContractSettingsStatuses);
    const isContractStateOpen = useAppSelector(selectIsContractStateOpen);

    const { wordsToSpin, showingWordIndex, setSpinning, setShowingWord } =
        useSpinningWheel();
    const publicClient = usePublicClient();
    const currentUserTotalNumberOfWords = useAppSelector(
        selectCurrentUserWordsCount,
    );
    const currentUserWords = useAppSelector(selectCurrentUserWords);
    const price = useAppSelector(selectBuyPrice);
    const correctedPrice = BigInt(price) + BigInt(10); // adding 10 wei (2e-14 USD) to cover fluctuations in price due to integer division (it will be sent back if not used)
    const nextWordIntroductionData = useAppSelector(
        selectNextWordIntroductionData,
    );

    const [typewriterText, setTypewriterText] = useState("");
    const [lastBoughtWord, setLastBoughtWord] = useState<{
        word: string;
        frequency: number;
        tokenId: number;
    } | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const { currentText } = useTypeWriter({
        text: typewriterText,
        delay: 100,
        infinite: false,
    });

    useEffect(() => {
        if (contract?.address) {
            dispatch(getRandomTokenPrice({ contract }));
            dispatch(getWordsOpenToBeBought({ contract }));
        }
    }, [contract, dispatch]);

    useEffect(() => {
        if (
            statuses.getRandomTokenPrice === "failed" ||
            (statuses.getWordsOpenToBeBought === "succeeded" &&
                wordsOpenToBeBought.length === 0)
        ) {
            setTypewriterText(
                TYPEWRITER_TEXT.NO_WORD_AVAILABLE(
                    nextWordIntroductionData?.wordNumbers,
                ),
            );
        }
    }, [
        nextWordIntroductionData,
        statuses.getRandomTokenPrice,
        statuses.getWordsOpenToBeBought,
        wordsOpenToBeBought.length,
    ]);

    const isLastWord = wordsOpenToBeBought.length === 1;

    return (
        <Box
            display="flex"
            gap={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            {currentText ? (
                <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                    {isLoading && <CircularProgress size={25} />}&nbsp;
                    <Typography fontSize={24}>
                        {currentText}&nbsp;
                        {wordsOpenToBeBought.length === 0 &&
                            nextWordIntroductionData &&
                            currentText ===
                                TYPEWRITER_TEXT.NO_WORD_AVAILABLE(
                                    nextWordIntroductionData?.wordNumbers,
                                ) &&
                            formatRemainingTime({
                                prefix: "in",
                                dateString: nextWordIntroductionData.date,
                                shouldIncludeSeconds:
                                    differenceInHours(
                                        new Date(nextWordIntroductionData.date),
                                        new Date(),
                                    ) < 1,
                            })}
                    </Typography>
                </Stack>
            ) : (
                <Typography fontSize={24}>
                    {wordsToSpin.length > 1
                        ? "Stop the wheel to buy a word"
                        : "Buy the last word"}
                    {rate ? (
                        <>
                            &nbsp;for $
                            <Typography
                                component="span"
                                fontSize={24}
                                sx={{
                                    textDecoration: "underline",
                                }}
                            >
                                {(
                                    Number(correctedPrice) *
                                    rate *
                                    10e-19
                                )?.toFixed(2)}
                            </Typography>
                        </>
                    ) : (
                        ""
                    )}
                </Typography>
            )}
            <SpinningWheel
                wordsToSpin={wordsToSpin}
                showingWordIndex={showingWordIndex}
            />
            {!selectedAccount ? (
                <ConnectButton
                    label={"Connect your wallet to stop the wheel"}
                />
            ) : (
                <Button
                    disabled={
                        statuses.getRandomTokenPrice !== "succeeded" ||
                        !isContractStateOpen ||
                        isLoading ||
                        !contract ||
                        !selectedAccount ||
                        wordsOpenToBeBought.length === 0
                    }
                    sx={{
                        fontWeight: "bold",
                    }}
                    variant="contained"
                    onClick={async () => {
                        if (!contract) {
                            console.error("No contract found to mint");
                            return;
                        }
                        if (!selectedAccount) {
                            console.error("No account found to mint");
                            return;
                        }

                        // Reinitializing data for people who are hasty
                        setSpinning(true);
                        dispatch(getWordsOpenToBeBought({ contract }));

                        try {
                            setTypewriterText(
                                TYPEWRITER_TEXT.CHECK_WALLET_TO_CONFIRM,
                            );

                            const tx = await contract.write.mint({
                                value: correctedPrice,
                            });
                            setIsLoading(true);
                            setTypewriterText(
                                TYPEWRITER_TEXT.PURCHASE_BEING_PROCESSED,
                            );

                            const receipt =
                                await publicClient.waitForTransactionReceipt({
                                    hash: tx,
                                });

                            if (receipt.status === "success") {
                                dispatch(
                                    setSnackbarFeedback({
                                        type: SeverityType.SUCCESS,
                                        message: "Transaction successful!",
                                    }),
                                );
                            } else {
                                console.error(`Error sending tx: ${receipt}`);
                                dispatch(
                                    setSnackbarFeedback({
                                        type: SeverityType.ERROR,
                                        message:
                                            "Transaction failed, please try again",
                                    }),
                                );
                            }

                            if (!isLastWord) {
                                setTypewriterText(
                                    TYPEWRITER_TEXT.RANDOMLY_PICKING_WORD,
                                );
                            }

                            // Wait for the word to be added to the user's words
                            let retries = 0;
                            const maxRetries = 20;
                            const retryDelay = 2000; // 2 seconds
                            let newWord;

                            while (retries < maxRetries) {
                                // We directly call the contract instead of using the Redux getCurrentUserWords action
                                // to prevent unnecessary switch of loading and so fetching of table words each time.
                                const newTotalWords =
                                    await contract.read.balanceOf(
                                        [selectedAccount],
                                        {
                                            account: selectedAccount,
                                        },
                                    );
                                const newWords =
                                    await contract.read.getUserTokenData([
                                        selectedAccount,
                                    ]);

                                if (
                                    newTotalWords >
                                    currentUserTotalNumberOfWords
                                ) {
                                    newWord = newWords.find(
                                        ({ word }) =>
                                            !currentUserWords.includes(word),
                                    );
                                    // Use the getCurrentUserWords action to update value in redux
                                    dispatch(
                                        getCurrentUserWords({
                                            contract,
                                            address: selectedAccount,
                                        }),
                                    );
                                    break; // Word has been added
                                }

                                await new Promise((resolve) =>
                                    setTimeout(resolve, retryDelay),
                                );
                                retries++;
                            }

                            if (retries === maxRetries) {
                                console.warn(
                                    "Timeout waiting for word to be added to user's words",
                                );
                            }

                            setSpinning(false);
                            if (newWord?.word) {
                                setShowingWord(newWord.word);
                                setLastBoughtWord({
                                    ...newWord,
                                    frequency: Number(
                                        newWord.frequency.toString(),
                                    ),
                                    tokenId: Number(newWord.tokenId.toString()),
                                });
                            }

                            setTypewriterText(
                                isLastWord
                                    ? TYPEWRITER_TEXT.LAST_WORD
                                    : TYPEWRITER_TEXT.YOU_VE_BOUGHT_A_WORD(
                                          newWord?.word,
                                      ),
                            );
                            setIsLoading(false);

                            setTimeout(() => {
                                dispatch(getWordsOpenToBeBought({ contract }));
                                setTypewriterText("");
                                setSpinning(true);
                            }, 10_000);
                        } catch (e) {
                            setTypewriterText(
                                TYPEWRITER_TEXT.OOPSY_ERROR_PLEASE_TRY_AGAIN,
                            );
                            console.error(e);
                            dispatch(
                                setSnackbarFeedback({
                                    type: SeverityType.ERROR,
                                    message: "Couldn't mint, please try again",
                                }),
                            );
                            setIsLoading(false);
                        }
                    }}
                >
                    Press to
                    {isLastWord ? " buy the word" : " stop the wheel"}
                </Button>
            )}
            <Typography
                color="secondary"
                my={2}
                sx={{
                    animation: "pulsing .75s infinite",
                }}
            >
                {wordsOpenToBeBought.length > 0
                    ? `Only ${wordsOpenToBeBought.length} word${wordsOpenToBeBought.length > 1 ? "s" : ""} left —`
                    : null}
                <NextWordIntroductionCountdown />
            </Typography>
            {lastBoughtWord && (
                <SellModal
                    open={Boolean(lastBoughtWord)}
                    onClose={() => {
                        setLastBoughtWord(null);
                    }}
                    title={`Congrats! You've won the word "${lastBoughtWord.word}"`}
                    tokenId={lastBoughtWord.tokenId}
                />
            )}
        </Box>
    );
};

export default BuyWord;
