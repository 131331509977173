// * ROUTES IN THE APP * ----------------------

// SubPath Routes
export const SUBPATHS = {};

// PATHS object with linked SUBPATHS
export const PATHS = {
    ERROR: "/error",
    LANDING: "/",
    NOT_FOUND: "/404",
    OFFERS: "/offers",
};

// * EVENTS * ---------------------------------

export const EVENT = {
    FREQUENCY_UPDATE: "FrequencyUpdate",
    FREQUENCIES_UPDATED: "FrequenciesUpdated",
    NEW_RENTED_WORD: "NewRentedWord",
    NEW_ROUND: "NewRound",
    INTRODUCING_NEW_WORD: "IntroducingNewWord",
    INTRODUCING_NEW_WORDS: "IntroducingNewWords",
    REWARDS_RECEIVED: "RewardsReceived",
};

export const TOP_25_WORDS_COUNT = 25;

export enum CURRENCY_SYMBOL {
    ETH = "ETH",
    USD = "USD",
    GWEI = "Gwei",
    WEI = "wei",
}

export type CURRENCY_SYMBOL_TYPE =
    (typeof CURRENCY_SYMBOL)[keyof typeof CURRENCY_SYMBOL];

export const ONE_GWEI_IN_WEI = "1000000000";
export const FOUR_DECIMALS_ETH_IN_WEI = "100000000000000";

export const TYPEWRITER_TEXT = {
    CHECK_WALLET_TO_CONFIRM: "Check your wallet to confirm the purchase...",
    PURCHASE_BEING_PROCESSED: "Purchase is being processed...",
    RANDOMLY_PICKING_WORD: "Randomly picking a word...",
    LAST_WORD: "You've bought the last word!",
    YOU_VE_BOUGHT_A_WORD: (newWord?: string) =>
        `You've bought a word! ${
            newWord
                ? `And that's:`
                : "Refresh the page in a few seconds to see your word"
        }`,
    OOPSY_ERROR_PLEASE_TRY_AGAIN:
        "Oopsy... there was an error, please try again",
    NO_WORD_AVAILABLE: (numberOfWords?: number) =>
        `${numberOfWords ? `${numberOfWords} n` : "N"}ew words available for purchase`,
};

export const MARKET_PLACE_CONTRACT_ADDRESS =
    import.meta.env.VITE_TEST_NET_CONTRACT_ADDRESS ??
    import.meta.env.VITE_CONTRACT_ADDRESS;
