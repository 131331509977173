import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import {
    AppBar,
    Badge,
    Box,
    Container,
    IconButton,
    Link as MaterialLink,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import useReservoirClient from "src/hooks/useReservoirClient";
import {
    getCurrentUserBids,
    getCurrentUserTokens,
    selectCurrentUserTokenIdsWithBidsToAccept,
} from "src/slices/tokenSlice";
import { getCurrentUserWords } from "src/slices/wordSlice";

import ConnectButton from "src/components/ConnectButton";
import RoundDetails from "src/components/RoundDetails";
import WordsSelectionToggle from "src/components/WordsSelectionToggle";

import { PATHS } from "src/constants";
import { ContractContext } from "src/contexts/ContractsContext";
import { WalletContext } from "src/contexts/WalletContext";
import { useAppDispatch, useAppSelector } from "src/store";

function Header() {
    const { selectedAccount } = useContext(WalletContext);
    const { contract } = useContext(ContractContext);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const theme = useTheme();
    const reservoirClient = useReservoirClient();
    const currentUserTokenIdsWithBidsToAccept = useAppSelector(
        selectCurrentUserTokenIdsWithBidsToAccept,
    );

    useEffect(() => {
        if (selectedAccount && contract?.address && reservoirClient) {
            dispatch(
                getCurrentUserWords({ contract, address: selectedAccount }),
            );
            // This allows us to know if current user has offers to answer
            dispatch(
                getCurrentUserTokens({
                    reservoirClient,
                    address: selectedAccount,
                }),
            );
            // This allows us to know if current user has made an offer on a token
            dispatch(
                getCurrentUserBids({
                    reservoirClient,
                    address: selectedAccount,
                }),
            );
        }
    }, [contract, selectedAccount, dispatch, reservoirClient]);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const items = [
        {
            name: "words",
            component: <WordsSelectionToggle />,
            condition: Boolean(selectedAccount),
            shouldCloseMenu: true,
        },
        {
            name: "bidsToAccept",
            component: (
                <IconButton onClick={() => navigate(PATHS.OFFERS)}>
                    <Badge
                        badgeContent={
                            currentUserTokenIdsWithBidsToAccept?.length
                        }
                        color="warning"
                    >
                        <NotificationsIcon color="primary" />
                    </Badge>
                </IconButton>
            ),
            condition: true,
            shouldCloseMenu: true,
        },
        {
            name: "connection",
            component: <ConnectButton />,
            condition: true,
            shouldCloseMenu: true,
        },
    ];
    const displayedItemsNumber = items.filter(
        ({ condition }) => condition,
    ).length;

    return (
        <>
            <AppBar position="static" sx={{ marginBottom: "2rem" }}>
                <RoundDetails />
                <Container maxWidth="xl">
                    <Toolbar
                        disableGutters
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            py: 1,
                        }}
                    >
                        <Stack
                            direction="row"
                            flex={1}
                            alignItems="center"
                            gap={1}
                            justifyContent="space-between"
                        >
                            <Link
                                to={PATHS.LANDING}
                                style={{ textDecoration: "none" }}
                            >
                                <Typography
                                    variant="h4"
                                    component="h1"
                                    color="primary"
                                    style={{
                                        color: theme.palette.primary.main,
                                        alignItems: "center",
                                        fontWeight: 600,
                                        fontSize: "1.5rem",
                                    }}
                                >
                                    words.fun
                                </Typography>
                            </Link>
                            <Stack direction="row" gap={2} mr={2}>
                                <MaterialLink
                                    href={import.meta.env.VITE_TELEGRAM_LINK}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <TelegramIcon />
                                </MaterialLink>
                                <MaterialLink
                                    href="https://x.com/wordsdotfun"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <XIcon />
                                </MaterialLink>
                            </Stack>
                        </Stack>
                        <Box
                            sx={{
                                display: {
                                    xxs: "none",
                                    xs:
                                        displayedItemsNumber <= 2
                                            ? "flex"
                                            : "none",
                                    sm:
                                        displayedItemsNumber <= 3
                                            ? "flex"
                                            : "none",
                                    md: "flex",
                                },
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{
                                    alignItems: "center",
                                }}
                            >
                                {items.map(
                                    ({ component, condition, name }) =>
                                        condition && (
                                            <React.Fragment
                                                key={`item-${name}`}
                                            >
                                                {component}
                                            </React.Fragment>
                                        ),
                                )}
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: {
                                    xxs: "flex",
                                    xs:
                                        displayedItemsNumber <= 2
                                            ? "none"
                                            : "flex",
                                    sm:
                                        displayedItemsNumber <= 3
                                            ? "none"
                                            : "flex",
                                    md: "none",
                                },
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                            >
                                {items.map(
                                    ({
                                        condition,
                                        component,
                                        name,
                                        shouldCloseMenu,
                                    }) =>
                                        condition && (
                                            <MenuItem
                                                key={`menu-item-${name}`}
                                                onClick={() =>
                                                    shouldCloseMenu &&
                                                    handleCloseNavMenu()
                                                }
                                            >
                                                {component}
                                            </MenuItem>
                                        ),
                                )}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}

export default Header;
